<template>
  <tr
    :class="{
      'does-not-need-evaluation':
        !workoutExercise.exercise.needs_evaluation || isTooEasyForEvaluation,
    }"
  >
    <td>
      <div
        class="action-col"
        :class="{ pause: workoutExercise.exercise.is_pause }"
      >
        <span>{{ index + 1 }} | {{ workoutExercise.sequenceNo }}</span>
        <button class="btn btn-primary btn-sm" @click="emit('add-set')">
          <span
            class="glyphicon glyphicon-plus"
            title="Pievienot nākamo piegājienu"
          ></span>
        </button>
        <button class="btn btn-danger btn-sm" @click="emit('remove')">
          <span class="glyphicon glyphicon-trash"></span>
        </button>
        <button
          class="btn btn-info btn-sm drag-handle"
          title="Pārvietot vingrojumu"
        >
          <span class="glyphicon glyphicon-move"></span>
        </button>
        <span
          v-if="specialVideoShownMessage"
          class="special-video-message text-info"
          v-html="specialVideoShownMessage"
        ></span>
      </div>
    </td>
    <td>
      <span>{{ workoutExercise.exercise.name }}</span>
    </td>
    <td>
      {{ tirednessPercentageDisplayValue }}
    </td>
    <td>
      <div
        v-if="!workoutExercise.exercise.is_pause && isReady"
        class="form-group"
        v-html="abilityDisplayText"
      ></div>
    </td>
    <td v-if="shouldShowColumns.reps">
      <div v-if="workoutExercise.exercise.has_reps" class="form-group">
        <input
          class="form-control w-50px"
          :readonly="
            !isReady ||
            ((workoutExercise.exercise.has_weight ||
              workoutExercise.exercise.has_resistance_bands) &&
              !!oneRepMaxEstimateWithTiredness &&
              lockedInput === LockedInput.reps)
          "
          :value="workoutExercise.reps"
          @input="handleRepsInput"
        />
      </div>
    </td>
    <td v-if="shouldShowColumns.weight">
      <div
        v-if="workoutExercise.exercise.has_weight"
        class="form-group weight-input-container"
      >
        <div v-if="!isReady || oneRepMaxEstimateWithTiredness">
          <input
            class="form-control w-55px"
            :readonly="!isReady || lockedInput === LockedInput.weight"
            :value="percentageOfMax"
            @input="handlePercentageOfMaxInput"
          />
          <span>% no 1RM</span>
        </div>
        <div>
          <input
            class="form-control w-55px"
            :readonly="
              !isReady ||
              (workoutExercise.exercise.has_weight &&
                !!oneRepMaxEstimateWithTiredness &&
                lockedInput === LockedInput.weight)
            "
            :value="workoutExercise.weight"
            @input="handleWeightInput"
          />
          <span>kg</span>
          <span v-if="workoutExercise.exercise.weight_is_for_each_side">
            (katrai rokai/pusei)
          </span>
        </div>
      </div>
    </td>
    <td v-if="shouldShowColumns.rpe">
      <div v-if="!workoutExercise.exercise.is_pause" class="form-group">
        <VueSelect
          class="rpe-select"
          :disabled="
            !isReady ||
            ((workoutExercise.exercise.has_weight ||
              workoutExercise.exercise.has_resistance_bands) &&
              oneRepMaxEstimateWithTiredness &&
              lockedInput === LockedInput.rpe)
          "
          label="label"
          :model-value="workoutExercise.rpe"
          :options="rpeOptions"
          @update:model-value="handleRpeInput"
        />
      </div>
    </td>
    <td v-if="shouldShowColumns.time">
      <div
        v-if="
          workoutExercise.exercise.is_pause || workoutExercise.exercise.has_time
        "
      >
        <div v-if="workoutExercise.exercise.has_time" class="form-group">
          <input
            class="form-control w-60px"
            :readonly="!isReady"
            :value="workoutExercise.time_seconds"
            @input="handleTimeSecondsInput"
          />
        </div>
        <VueSelect
          v-else-if="workoutExercise.exercise.is_pause"
          v-model="selectedPauseLength"
          label="label"
          :options="pauseLengthOptions"
        />
        <span v-if="workoutExercise.exercise.time_is_for_each_side">
          (katrai pusei)
        </span>
      </div>
    </td>
    <td v-if="shouldShowColumns.resistance_bands">
      <div v-if="workoutExercise.exercise.has_resistance_bands">
        <VueSelect
          v-model="workoutExercise.resistance_bands"
          class="bands-select"
          label="label"
          :options="resistanceBandOptions"
        />
      </div>
    </td>
    <td v-if="shouldShowColumns.mode">
      <div v-if="workoutExercise.exercise.has_mode" class="form-group">
        <input
          class="form-control w-50px"
          :value="workoutExercise.mode"
          @input="
            emit(
              'update:workout-exercise-property',
              'mode',
              parseInt(($event.target as HTMLInputElement).value),
            )
          "
        />
      </div>
    </td>
    <td v-if="shouldShowColumns.incline_percent">
      <div
        v-if="workoutExercise.exercise.has_incline_percent"
        class="form-group"
      >
        <input
          class="form-control w-50px"
          :value="workoutExercise.incline_percent"
          @input="
            emit(
              'update:workout-exercise-property',
              'incline_percent',
              floatOrEmpty($event),
            )
          "
        />
      </div>
    </td>
    <td v-if="shouldShowColumns.pace">
      <div v-if="workoutExercise.exercise.has_pace" class="form-group">
        <input
          class="form-control w-50px"
          :value="workoutExercise.pace_min_km"
          @input="
            emit(
              'update:workout-exercise-property',
              'pace_min_km',
              floatOrEmpty($event),
            )
          "
        />
      </div>
    </td>
    <td v-if="shouldShowColumns.speed">
      <div v-if="workoutExercise.exercise.has_speed" class="form-group">
        <input
          class="form-control w-50px"
          :value="workoutExercise.speed_km_h"
          @input="
            emit(
              'update:workout-exercise-property',
              'speed_km_h',
              floatOrEmpty($event),
            )
          "
        />
      </div>
    </td>
    <td v-if="shouldShowColumns.distance">
      <div v-if="workoutExercise.exercise.has_distance" class="form-group">
        <input
          class="form-control w-50px"
          :value="workoutExercise.distance_m"
          @input="
            emit(
              'update:workout-exercise-property',
              'distance_m',
              floatOrEmpty($event),
            )
          "
        />
      </div>
    </td>
    <td v-if="shouldShowColumns.pulse">
      <div v-if="workoutExercise.exercise.has_pulse" class="form-group">
        <input
          class="form-control w-50px"
          :value="workoutExercise.pulse"
          @input="
            emit(
              'update:workout-exercise-property',
              'pulse',
              floatOrEmpty($event),
            )
          "
        />
      </div>
    </td>
    <td v-if="shouldShowColumns.height">
      <div v-if="workoutExercise.exercise.has_height" class="form-group">
        <input
          class="form-control w-50px"
          :value="workoutExercise.height"
          @input="
            emit(
              'update:workout-exercise-property',
              'height',
              floatOrEmpty($event),
            )
          "
        />
      </div>
    </td>
  </tr>
</template>

<script lang="ts" setup>
import {
  pauseLengths,
  resistanceBandOptions,
  Rpe,
  rpes,
} from '../helpers/Global'
import RpeCalculatorForWeight from '../helpers/RpeCalculatorForWeight'
import { computed, onMounted, ref, watch, watchEffect } from 'vue'
import WorkoutExerciseVM, {
  resistanceBandToWeight,
} from '../models/WorkoutExerciseVM'
import {
  AverageAbility,
  AverageAbilityType,
  LockedInput,
} from '../types/common'
import averageAbilityCacheStore from '../stores/useAverageAbilityStore'
import RestTimeCalculator from '../helpers/RestTimeCalculator'
import VueSelect from 'vue-select'
import { Workout } from '../types/Workout'
import { RpeCalculatorForRepsOrTime } from '../helpers/RpeCalculatorForRepsOrTime'
import { findClosestNumber, roundedToFixed, roundToHalf } from '../helpers/Math'

const props = defineProps<{
  workout: Partial<Workout>
  workoutExercise: WorkoutExerciseVM
  index: number
  shouldShowColumns: Record<string, boolean>
  lockedInput: LockedInput | null
  userId: number
}>()

const emit = defineEmits<{
  (e: 'remove'): void
  (e: 'add-set'): void
  (e: 'update:locked-input', value: LockedInput | null): void
  <T extends keyof typeof props.workoutExercise>(
    e: 'update:workout-exercise-property',
    property: T,
    value: (typeof props.workoutExercise)[T],
  )
}>()

const { getExerciseAbility, setExerciseAbility } = averageAbilityCacheStore()

watch(
  () => props.workoutExercise,
  async (n) => {
    if (
      oneRepMaxEstimate.value ||
      maxRepsOrTimeEstimate.value ||
      maxKgSecEstimate.value ||
      !n.rpe ||
      (n.exercise.has_weight && !n.weight) ||
      (n.exercise.has_reps && !n.reps) ||
      (n.exercise.has_time && !n.time_seconds) ||
      (n.exercise.has_resistance_bands && !n.resistance_bands)
    ) {
      return
    }
    try {
      const averageAbility = await getExerciseAbility(
        props.workoutExercise.exercise.id,
        props.userId,
      )
      if (averageAbility?.ability) setAverageAbility(averageAbility)
      else if (props.workoutExercise.rpe) {
        // setExerciseAbility(
        //   props.workoutExercise as WorkoutExerciseVM & {
        //     rpe: { value: Rpe; label: Rpe }
        //   },
        // )
        const estimatedAbility = await getExerciseAbility(
          props.workoutExercise.exercise.id,
          props.userId,
        )
        if (estimatedAbility) setAverageAbility(estimatedAbility)
      }
    } catch (e) {
      console.error(e)
    }
  },
  { deep: true },
)

const withTiredness = (
  ability: number | undefined,
  tirednessPercentage: number,
) => {
  return ability ? (ability * (100 - tirednessPercentage)) / 100 : ability
}

const floatOrEmpty = (e: Event) => {
  const parsed = parseFloat((e.target as HTMLInputElement).value)
  return isNaN(parsed) ? null : parsed
}

const percentageOfMax = ref<number | null>(null)
const handlePercentageOfMaxInput = (e: Event) => {
  percentageOfMax.value = floatOrEmpty(e)
  setWeightKgFromPercentageOf1rm()
  afterAnyWeightInput()
}

const handleWeightInput = (e: Event) => {
  emit('update:workout-exercise-property', 'weight', floatOrEmpty(e))
  if (oneRepMaxEstimateWithTiredness.value) {
    setWeightPercentageOfOneRepMaxFromKg()
    afterAnyWeightInput()
  }
}

const afterAnyWeightInput = () => {
  if (!props.lockedInput || props.lockedInput === LockedInput.reps) {
    setRepsOrTime()
  } else if (props.lockedInput === LockedInput.rpe) {
    setRpe()
  }
}

const shouldSetWeight = computed(
  () =>
    props.lockedInput === LockedInput.weight &&
    props.workoutExercise.exercise.has_weight,
)

const afterRpeOrRepsInput = (
  lockedInput: LockedInput,
  setter: typeof setRepsOrTime | typeof setRpe,
) => {
  if (oneRepMaxEstimateWithTiredness.value && shouldSetWeight.value) {
    setPercentageOfMax()
    setWeightKgFromPercentageOf1rm()
  } else if (
    (oneRepMaxEstimateWithTiredness.value &&
      props.lockedInput === lockedInput) ||
    maxRepsOrTimeEstimateWithTiredness.value
  ) {
    setter()
  }
}

const handleRepsInput = (e: Event) => {
  emit('update:workout-exercise-property', 'reps', floatOrEmpty(e))
  afterRpeOrRepsInput(LockedInput.rpe, setRpe)
}

const handleRpeInput = (value: { value: Rpe; label: Rpe }) => {
  emit('update:workout-exercise-property', 'rpe', value)
  afterRpeOrRepsInput(LockedInput.reps, setRepsOrTime)
}

const handleTimeSecondsInput = (e: Event) => {
  emit('update:workout-exercise-property', 'time_seconds', floatOrEmpty(e))

  if (
    !oneRepMaxEstimateWithTiredness.value &&
    !maxRepsOrTimeEstimateWithTiredness.value
  ) {
    return
  }
  if (props.lockedInput === LockedInput.rpe || !props.lockedInput) {
    setRpe()
  } else if (shouldSetWeight.value) {
    setPercentageOfMax()
    setWeightKgFromPercentageOf1rm()
  } else if (props.lockedInput === LockedInput.reps) {
    setRepsOrTime()
  }
}

const oneRepMaxEstimate = ref<number>()
const maxRepsOrTimeEstimate = ref<number>()
const maxKgSecEstimate = ref<number>()

const tirednessPercentageCapped = computed(() =>
  props.workoutExercise.tiredness_percentage > 100
    ? 100
    : props.workoutExercise.tiredness_percentage,
)

const isTooEasyForEvaluation = computed(() => {
  return (
    !props.workoutExercise.rpe?.value || props.workoutExercise.rpe?.value <= 5
  )
})

const oneRepMaxEstimateWithTiredness = computed(() => {
  if (isTooEasyForEvaluation.value) return
  return withTiredness(oneRepMaxEstimate.value, tirednessPercentageCapped.value)
})

const maxRepsOrTimeEstimateWithTiredness = computed(() => {
  if (isTooEasyForEvaluation.value) return
  return withTiredness(
    maxRepsOrTimeEstimate.value,
    tirednessPercentageCapped.value,
  )
})

const maxKgSecEstimateWithTiredness = computed(() => {
  if (isTooEasyForEvaluation.value) return
  return withTiredness(maxKgSecEstimate.value, tirednessPercentageCapped.value)
})

const rpeOptions = ref(rpes.map((rpe) => ({ value: rpe, label: rpe })))
const selectedPauseLength = ref(
  (() => {
    const seconds = props.workoutExercise.exercise.is_pause
      ? props.workoutExercise.time_seconds ?? 30
      : 30
    return { value: seconds, label: seconds }
  })(),
)

const pauseLengthOptions = computed(() =>
  props.workoutExercise.exercise.is_pause
    ? pauseLengths.map((s) => ({ value: s, label: s }))
    : null,
)

const specialVideoShownMessage = computed(() => {
  if (!props.workoutExercise.exercise.videos) return null

  let resForReps = false
  let resForTime = false
  let resForBoth = false
  const specialVideoToShow = props.workoutExercise.exercise.videos.find((v) => {
    const repsMatch =
      !!v.reps &&
      !!props.workoutExercise.reps &&
      props.workoutExercise.reps == v.reps
    const timeMatches =
      !!v.time_seconds &&
      !!props.workoutExercise.time_seconds &&
      props.workoutExercise.time_seconds == v.time_seconds

    const forReps = !!v.reps && !v.time_seconds && repsMatch
    const forTime = !!v.time_seconds && !v.reps && timeMatches
    const forBoth = !!v.reps && !!v.time_seconds && repsMatch && timeMatches

    if (forReps || forTime || forBoth) {
      resForReps = forReps
      resForTime = forTime
      resForBoth = forBoth
      return true
    }
  })

  if (specialVideoToShow) {
    if (resForReps) {
      return (
        'Tiks rādīts īpašais video <strong>' +
        props.workoutExercise.reps +
        ' reizēm</strong>'
      )
    }
    if (resForTime) {
      return (
        'Tiks rādīts īpašais video <strong>' +
        props.workoutExercise.time_seconds +
        ' sekundēm</strong>'
      )
    }
    if (resForBoth) {
      return (
        'Tiks rādīts īpašais video <strong>' +
        props.workoutExercise.reps +
        ' reizēm</strong> un <strong>' +
        props.workoutExercise.time_seconds +
        ' sekundēm</strong>'
      )
    }
  }

  return null
})

const abilityDisplayText = computed(() => {
  const oneRepMax = oneRepMaxEstimateWithTiredness.value
  const maxRepsOrTime = maxRepsOrTimeEstimateWithTiredness.value
  const maxKgSec = maxKgSecEstimateWithTiredness.value

  if (typeof oneRepMax !== 'undefined') {
    return roundedToFixed(oneRepMax, 1) + ' kg'
  }

  if (typeof maxRepsOrTime !== 'undefined') {
    const suffix = props.workoutExercise.exercise.has_reps
      ? ' reizes'
      : ' sekundes'
    return roundedToFixed(maxRepsOrTime, 1) + suffix
  }

  if (maxKgSec) return `${Math.round(maxKgSec)} kgsec`

  if (isTooEasyForEvaluation.value) return '<em>(pārāk viegls RPE)</em>'

  return '<em>(nav)</em>'
})

const setPercentageOfMax = () => {
  percentageOfMax.value =
    !props.workoutExercise.rpe?.value || !props.workoutExercise.reps
      ? null
      : RpeCalculatorForWeight.calculatePercentageOfOneRepMax(
          props.workoutExercise.rpe.value,
          props.workoutExercise.reps,
        ) * 100
}

const weightDecimalsCount = 1
const setWeightKgFromPercentageOf1rm = () => {
  emit(
    'update:workout-exercise-property',
    'weight',
    !percentageOfMax.value || !oneRepMaxEstimateWithTiredness.value
      ? null
      : parseFloat(
          roundedToFixed(
            (oneRepMaxEstimateWithTiredness.value * percentageOfMax.value) /
              100,
            weightDecimalsCount,
          ),
        ),
  )
}

const setWeightPercentageOfOneRepMaxFromKg = () => {
  percentageOfMax.value =
    !props.workoutExercise.weight || !oneRepMaxEstimateWithTiredness.value
      ? null
      : parseInt(
          (
            (props.workoutExercise.weight /
              oneRepMaxEstimateWithTiredness.value) *
            100
          ).toFixed(0),
        )
}

const setRpe = () => {
  if (props.workoutExercise.reps) {
    if (
      oneRepMaxEstimateWithTiredness.value &&
      props.workoutExercise.exercise.has_resistance_bands
    ) {
      const targetRpe =
        props.workoutExercise.resistance_bands &&
        typeof props.workoutExercise.resistance_bands === 'object' &&
        'value' in props.workoutExercise.resistance_bands
          ? roundToHalf(
              RpeCalculatorForWeight.calculateRpe(
                props.workoutExercise.reps,
                resistanceBandToWeight(
                  props.workoutExercise.resistance_bands.value,
                ) / oneRepMaxEstimateWithTiredness.value,
              ),
            )
          : null
      emit(
        'update:workout-exercise-property',
        'rpe',
        !props.workoutExercise.reps ||
          props.workoutExercise.reps < 1 ||
          !targetRpe
          ? null
          : rpeOptions.value.find((x) => x.value === targetRpe) ?? null,
      )
    } else if (oneRepMaxEstimateWithTiredness.value && percentageOfMax.value) {
      const targetRpe = roundToHalf(
        RpeCalculatorForWeight.calculateRpe(
          props.workoutExercise.reps,
          percentageOfMax.value / 100,
        ),
      )
      emit(
        'update:workout-exercise-property',
        'rpe',
        !props.workoutExercise.reps || props.workoutExercise.reps < 1
          ? null
          : rpeOptions.value.find((x) => x.value === targetRpe) ?? null,
      )
    } else if (
      maxRepsOrTimeEstimateWithTiredness.value &&
      props.workoutExercise.rpe?.value
    ) {
      emit(
        'update:workout-exercise-property',
        'rpe',
        rpeOptions.value.find(
          (x) =>
            x.value ===
            findClosestNumber(rpes, props.workoutExercise.rpe!.value),
        ) ?? null,
      )
    }
  } else if (props.workoutExercise.time_seconds) {
    if (
      maxRepsOrTimeEstimateWithTiredness.value &&
      props.workoutExercise.rpe &&
      props.workoutExercise.exercise.has_time
    ) {
      const targetRpe = RpeCalculatorForRepsOrTime.calculateRpe(
        props.workoutExercise.time_seconds /
          maxRepsOrTimeEstimateWithTiredness.value,
      )
      emit(
        'update:workout-exercise-property',
        'rpe',
        rpeOptions.value.find(
          (x) => x.value === findClosestNumber(rpes, targetRpe),
        ) ?? null,
      )
    }
  }
}
const setRepsOrTime = () => {
  const payload = ['update:workout-exercise-property', 'reps'] as const

  if (
    props.workoutExercise.exercise.has_resistance_bands &&
    oneRepMaxEstimateWithTiredness.value
  ) {
    emit(
      ...payload,
      props.workoutExercise.resistance_bands &&
        typeof props.workoutExercise.resistance_bands == 'object' &&
        'value' in props.workoutExercise.resistance_bands &&
        props.workoutExercise.rpe
        ? Math.round(
            RpeCalculatorForWeight.calculateReps(
              props.workoutExercise.rpe.value,
              resistanceBandToWeight(
                props.workoutExercise.resistance_bands.value,
              ) / oneRepMaxEstimateWithTiredness.value,
            ),
          )
        : null,
    )
  } else if (oneRepMaxEstimateWithTiredness.value) {
    emit(
      ...payload,
      percentageOfMax.value && props.workoutExercise.rpe
        ? Math.round(
            RpeCalculatorForWeight.calculateReps(
              props.workoutExercise.rpe.value,
              percentageOfMax.value / 100,
            ),
          )
        : null,
    )
  } else if (
    maxRepsOrTimeEstimateWithTiredness.value &&
    props.workoutExercise.rpe &&
    props.workoutExercise.exercise.has_reps
  ) {
    emit(
      ...payload,
      Math.round(
        Math.round(
          RpeCalculatorForRepsOrTime.calculatePercOfMaxTime(
            props.workoutExercise.rpe.value,
          ) * maxRepsOrTimeEstimateWithTiredness.value,
        ),
      ),
    )
  } else if (
    maxRepsOrTimeEstimateWithTiredness.value &&
    props.workoutExercise.rpe &&
    props.workoutExercise.exercise.has_time
  ) {
    emit(
      'update:workout-exercise-property',
      'time_seconds',
      Math.round(
        RpeCalculatorForRepsOrTime.calculatePercOfMaxTime(
          props.workoutExercise.rpe.value,
        ) * maxRepsOrTimeEstimateWithTiredness.value,
      ),
    )
  }
}

watch(
  selectedPauseLength,
  (n) => {
    if (n && props.workoutExercise.exercise.is_pause) {
      emit('update:workout-exercise-property', 'time_seconds', n.value)
    }
  },
  { immediate: true },
)

const isReady = ref(false)

onMounted(async () => {
  try {
    const averageAbility = await getExerciseAbility(
      props.workoutExercise.exercise.id,
      props.userId,
    )
    if (averageAbility?.ability) setAverageAbility(averageAbility)
    else if (props.workoutExercise.rpe) {
      // setExerciseAbility(
      //   props.workoutExercise as WorkoutExerciseVM & {
      //     rpe: { value: Rpe; label: Rpe }
      //   },
      // )
      const estimatedAbility = await getExerciseAbility(
        props.workoutExercise.exercise.id,
        props.userId,
      )
      if (estimatedAbility) setAverageAbility(estimatedAbility)
    }
  } catch (e) {
    console.error(e)
  }
  isReady.value = true
})

const setAverageAbility = (averageAbility: AverageAbility) => {
  if (averageAbility.type === AverageAbilityType['1RM']) {
    oneRepMaxEstimate.value = averageAbility.ability
    setPercentageOfMax()
    setWeightKgFromPercentageOf1rm()
    afterAnyWeightInput()
  } else if (averageAbility.type === AverageAbilityType.REPS_OR_TIME) {
    maxRepsOrTimeEstimate.value = averageAbility.ability
    emit('update:locked-input', null)
    setRepsOrTime()
    setRpe()
  } else if (averageAbility.type === AverageAbilityType.KGSEC) {
    maxKgSecEstimate.value = averageAbility.ability
    emit('update:locked-input', null)
    setRepsOrTime()
    setRpe()
  }
}

watchEffect(() => {
  if (!props.workout.workoutExercises) return

  emit(
    'update:workout-exercise-property',
    'tiredness_percentage',
    RestTimeCalculator.getTirednessPercentageForWorkoutExercise(
      props.workout.workoutExercises,
      props.workoutExercise,
      props.index,
    ),
  )

  if (oneRepMaxEstimateWithTiredness.value) {
    if (shouldSetWeight.value) {
      setWeightKgFromPercentageOf1rm()
    } else if (props.lockedInput !== LockedInput.weight) {
      setWeightPercentageOfOneRepMaxFromKg()
      if (percentageOfMax.value && percentageOfMax.value > 100) {
        percentageOfMax.value = 100
        setWeightKgFromPercentageOf1rm()
      }
      if (props.lockedInput === LockedInput.reps) {
        setRepsOrTime()
        if (props.workoutExercise.reps === 0) {
          emit('update:workout-exercise-property', 'reps', 1)
          setPercentageOfMax()
        }
      } else {
        setRpe()
      }
    }
  } else if (maxRepsOrTimeEstimateWithTiredness.value) {
    setRepsOrTime()
  }
})

const tirednessPercentageDisplayValue = computed(() => {
  if (props.workoutExercise.exercise.is_pause) return ''
  return props.workoutExercise.tiredness_percentage > 100
    ? '100%+'
    : Math.round(props.workoutExercise.tiredness_percentage) + '%'
})
</script>

<style lang="scss" scoped>
tr.does-not-need-evaluation {
  background: rgba(0, 255, 255, 0.1);
}

td {
  padding: 4px !important;
  vertical-align: middle;
}

.action-col {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;

  &.pause {
    justify-content: flex-end;
  }
}

.form-group {
  margin-bottom: 4px !important;
}

.special-video-message {
  display: inline-block;
  font-size: 12px;
}

.w-50px {
  width: 50px;
}

.w-55px {
  width: 55px;
}

.w-60px {
  width: 60px;
}

.weight-input-container {
  display: flex;
  text-align: center;
  font-size: 9px;
}

.rpe-select {
  width: 95px;
}

.bands-select {
  width: 155px;
}
</style>
