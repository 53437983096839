<template>
  <div class="timer-container" :class="[small ? 'timer-container--small' : '']">
    <ClientBaseTimer
      :has-countdown="hasCountdown"
      :is-pause="isPause"
      :start-instantly="startInstantly"
      :start-instantly-countdown-seconds="8"
      :time="time"
      @finished="onFinished"
    />
  </div>
</template>

<script setup lang="ts">
import ClientBaseTimer from './ClientBaseTimer.vue'

const props = defineProps<{
  time: number
  startInstantly: boolean
  redirectWhenFinished: boolean
  continueBtnHref: string
  small?: boolean
  hasCountdown?: boolean
  isPause?: boolean
}>()

const onFinished = () => {
  if (props.redirectWhenFinished && props.continueBtnHref) {
    window.location.href = props.continueBtnHref
  }
}
</script>

<style lang="scss" scoped>
.timer-container {
  padding: 8px 64px;
}

.timer-container--small {
  padding: 8px 32px;
}
</style>
