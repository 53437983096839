<template>
  <div class="workout-creation-container">
    <div class="row">
      <ul id="exercise-tabs" class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a
            id="student-tab"
            aria-controls="student"
            aria-selected="false"
            class="nav-link"
            data-toggle="tab"
            href="#student"
            role="tab"
          >
            Klients
          </a>
        </li>
        <li class="active nav-item">
          <a
            id="previous-workouts-tab"
            aria-controls="previous-workouts"
            aria-selected="false"
            class="nav-link"
            data-toggle="tab"
            href="#previous-workouts"
            role="tab"
          >
            Iepriekšējie treniņi
          </a>
        </li>
        <li class="nav-item">
          <a
            id="last-workouts-text-tab"
            aria-controls="last-workouts-text"
            aria-selected="false"
            class="nav-link"
            data-toggle="tab"
            href="#last-workouts-text"
            role="tab"
          >
            3 pēdējie treniņi priekš chatgpt
          </a>
        </li>
        <li class="nav-item">
          <a
            id="workout-creation-tab"
            aria-controls="workout-creation"
            aria-selected="false"
            class="nav-link"
            data-toggle="tab"
            href="#workout-creation-main"
            role="tab"
          >
            Treniņa izveidošana
          </a>
        </li>
        <li class="nav-item text-danger">
          <a
            id="danger-zone-tab"
            aria-controls="danger-zone"
            aria-selected="false"
            class="nav-link"
            data-toggle="tab"
            href="#danger-zone"
            role="tab"
          >
            Bīstamā zona
          </a>
        </li>
        <li class="nav-item text-danger">
          <a
            id="chatgpt-parsing-tab"
            aria-controls="chatgpt-parsing-demo"
            aria-selected="false"
            class="nav-link"
            data-toggle="tab"
            href="#chatgpt-parsing-demo"
            role="tab"
          >
            ChatGPT teksta parsēšana
          </a>
        </li>
      </ul>
    </div>

    <div class="row tab-content">
      <div
        id="student"
        aria-labelledby="student-tab"
        class="fade tab-pane"
        role="tabpanel"
      >
        <div v-if="user" class="col-md-12">
          <p>Vārds: {{ user.first_name }} {{ user.last_name }}</p>
          <p>E-pasts: {{ user.email }}</p>
          <p>Tel. nr.: {{ user.phone_number }}</p>
          <p>Valoda: {{ user.language }}</p>
          <div v-if="user.clientData">
            <hr />
            <p>Ķermeņa svars: {{ user.clientData.bodyweight }}</p>
            <p>Mērķis: {{ user.clientData.goal }}</p>
            <p>Pieredze: {{ user.clientData.experience }}</p>
            <p>Problēmas: {{ user.clientData.problems }}</p>
            <p>Operācijas: {{ user.clientData.operations }}</p>
            <p>Asinsanalīzes: {{ user.clientData.blood_analysis }}</p>
            <p>Emocionālais stāvoklis: {{ user.clientData.emotional_state }}</p>
            <div>
              <p>Piezīmes:</p>
              <p v-html="user.clientData.notes"></p>
            </div>
          </div>
        </div>
      </div>

      <div
        id="previous-workouts"
        aria-labelledby="previous-workouts-tab"
        class="active fade in tab-pane"
        role="tabpanel"
      >
        <div class="col-md-12">
          <LastWorkoutsTable
            v-if="userWorkouts?.length"
            :has-backup-workout="hasBackupWorkout"
            :workouts="userWorkouts as Workout[]"
            @reload-user-workouts="loadUserWorkouts"
          />
          <p v-else-if="userWorkoutsLoading" class="loader"></p>
          <p v-else>Klientam vēl nav bijis neviens treniņš!</p>
        </div>
      </div>

      <div
        id="last-workouts-text"
        aria-labelledby="last-workouts-text-tab"
        class="fade tab-pane"
        role="tabpanel"
      >
        <div class="col-md-12">
          <div
            v-if="lastWorkoutsTextForChatgpt"
            contenteditable
            v-html="lastWorkoutsTextForChatgpt"
          ></div>
          <p v-else-if="userWorkoutsLoading" class="loader"></p>
          <p v-else>Klientam vēl nav bijis neviens treniņš!</p>
        </div>
      </div>

      <div
        id="workout-creation-main"
        aria-labelledby="workout-creation-tab"
        class="fade tab-pane"
        role="tabpanel"
      >
        <div class="col-md-3">
          <ul id="exercise-tabs" class="nav nav-tabs" role="tablist">
            <li class="active nav-item">
              <a
                id="exercises-tab"
                aria-controls="exercises"
                aria-selected="false"
                class="nav-link"
                data-toggle="tab"
                href="#exercises"
                role="tab"
              >
                Vingrojumi
              </a>
            </li>
            <li class="nav-item">
              <a
                id="templates-tab"
                aria-controls="templates"
                aria-selected="false"
                class="nav-link"
                data-toggle="tab"
                href="#templates"
                role="tab"
              >
                Šabloni
              </a>
            </li>
          </ul>

          <div id="exercise-tab-content" class="tab-content">
            <div
              id="exercises"
              aria-labelledby="exercises-tab"
              class="active fade in tab-pane"
              role="tabpanel"
            >
              <h4>Vingrojumu meklēšana</h4>
              <ul id="exercise-tabs" class="nav nav-tabs" role="tablist">
                <li class="active nav-item">
                  <a
                    id="exercises-tab"
                    aria-controls="exercises"
                    aria-selected="true"
                    class="nav-link"
                    data-toggle="tab"
                    href="#by-name"
                    role="tab"
                  >
                    Pēc nosaukuma
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    id="templates-tab"
                    aria-controls="templates"
                    aria-selected="false"
                    class="nav-link"
                    data-toggle="tab"
                    href="#by-tags"
                    role="tab"
                  >
                    Pēc tagiem
                  </a>
                </li>
              </ul>

              <ul v-if="tags" class="list-group position-relative">
                <div id="by-name-tab-content" class="tab-content">
                  <div
                    id="by-name"
                    aria-labelledby="exercises-tab"
                    class="active fade in tab-pane"
                    role="tabpanel"
                  >
                    <div class="list-group-item">
                      <div class="name-filter-container">
                        <input
                          v-model="exerciseNameFilter"
                          class="form-control"
                          placeholder="Ievadiet vismaz 3 simbolus..."
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="by-tags"
                    aria-labelledby="exercises-tab"
                    class="fade tab-pane"
                    role="tabpanel"
                  >
                    <li class="list-group-item" :style="zIndexStyle">
                      <h4>Tagu atlase</h4>
                      <ul class="pl-0">
                        <li
                          v-for="(_, i) in selectedTagGroups"
                          :key="i"
                          class="list-group-item selected-tag-group"
                        >
                          <VueSelect
                            v-model="selectedTagGroups[i]"
                            label="value"
                            multiple
                            :options="tags"
                          />
                          <div v-if="i !== selectedTagGroups.length - 1">
                            VAI
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li
                      v-if="tagTypeSelectOptions"
                      class="list-group-item"
                      :style="zIndexStyle"
                    >
                      <h4>Tagu tipu atlase</h4>
                      <VueSelect
                        v-model="selectedTagTypes"
                        label="label"
                        multiple
                        :options="tagTypeSelectOptions"
                      />
                    </li>
                    <li class="list-group-item" :style="zIndexStyle">
                      <h4>Vingrojumu popularitātes atlase</h4>
                      <VueSelect
                        v-model="selectedExercisePopularity"
                        label="label"
                        :options="exercisePopularitySelectOptions"
                      />
                    </li>
                  </div>
                </div>

                <li
                  v-show="exercisesLoading"
                  class="disabled-overlay list-group-item"
                >
                  <div class="loader"></div>
                </li>
                <li
                  v-if="exercises"
                  class="list-group-item"
                  :style="zIndexStyle"
                >
                  <p class="exercise-creation-container position-relative">
                    <button
                      class="btn btn-default btn-sm mr-8"
                      title="Izveidot vingrojumu"
                      @click="showCreateExerciseModal = true"
                    >
                      <span class="glyphicon glyphicon-plus"></span>
                    </button>
                    <span>Vingrojumi</span>
                    <button
                      class="btn btn-secondary btn-sm ml-8"
                      @click="exercisesCollapsed = !exercisesCollapsed"
                    >
                      <span
                        :class="`glyphicon glyphicon-chevron-${
                          exercisesCollapsed ? 'down' : 'up'
                        }`"
                      ></span>
                    </button>
                  </p>
                  <label
                    v-show="workout.workoutExercises.length > 0"
                    class="insert-after-label"
                  >
                    Ievietot pēc vingrojuma #
                    <input
                      v-model="exerciseNumberToInsertNewExercisesAfter"
                      class="form-control insert-after-input"
                      :max="workout.workoutExercises.length"
                      min="1"
                      type="number"
                    />
                  </label>
                </li>
                <div v-show="!exercisesCollapsed && exercises">
                  <li
                    v-for="exercise in exercises"
                    :key="(exercise as ExerciseVM).id"
                    class="exercise-li list-group-item"
                    :style="zIndexStyle"
                  >
                    <span>
                      <a
                        :href="
                          '/exercises/view?id=' + (exercise as ExerciseVM).id
                        "
                        target="_blank"
                        title="Apskatīt vingrojumu"
                      >
                        <span class="glyphicon glyphicon-eye-open"></span>
                      </a>
                      <span class="ml-8">
                        {{ (exercise as ExerciseVM).name }}
                      </span>
                    </span>
                    <button
                      class="btn btn-primary btn-sm"
                      @click="addExercise(exercise)"
                    >
                      <span
                        class="glyphicon glyphicon-plus"
                        title="Pievienot treniņam"
                      ></span>
                    </button>
                  </li>
                </div>
                <li
                  v-if="exercises && exercises.length === 20"
                  class="list-group-item"
                  :style="zIndexStyle"
                >
                  Ielādēti pirmie 20 vingrojumi, kas atbilst atlasei.
                </li>
                <li
                  v-else-if="exercises && !exercises.length"
                  class="list-group-item"
                  :style="zIndexStyle"
                >
                  <span>Nav atrasts neviens vingrojums!</span>
                  <button
                    v-if="exerciseNameFilter"
                    class="btn btn-sm btn-success"
                    :disabled="creatingExercise"
                    @click="showCreateExerciseModal = true"
                  >
                    Izveidot <strong>{{ exerciseNameFilter }}</strong>
                  </button>
                </li>
                <li
                  v-if="pauseStore.pause"
                  class="list-group-item"
                  :style="zIndexStyle"
                >
                  <p class="pause-item">
                    <span>
                      <a
                        :href="'/exercises/view?id=' + pauseStore.pause.id"
                        target="_blank"
                        title="Apskatīt pauzi"
                      >
                        <span class="glyphicon glyphicon-eye-open"></span>
                      </a>
                      <span class="ml-8">{{ pauseStore.pause.name }}</span>
                    </span>
                    <button
                      class="btn btn-primary btn-sm"
                      @click="addExercise(pauseStore.pause)"
                    >
                      <span
                        class="glyphicon glyphicon-plus"
                        title="Pievienot treniņam"
                      ></span>
                    </button>
                  </p>
                </li>
              </ul>
            </div>
            <div
              id="templates"
              aria-labelledby="templates-tab"
              class="fade tab-pane"
              role="tabpanel"
            >
              <ul v-if="templates" class="list-group">
                <li
                  v-for="template in templates"
                  :key="template.id"
                  class="list-group-item"
                >
                  <span class="mr-8">
                    {{ template.title }}
                    ({{ template.templateExercises.length }})
                  </span>
                  <button
                    class="btn btn-primary btn-sm"
                    @click="addTemplate(template)"
                  >
                    <span
                      class="glyphicon glyphicon-plus"
                      title="Pievienot treniņam"
                    ></span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-md-9">
          <label class="form-group">
            Apraksts:
            <input v-model="workout.description" class="form-control" />
          </label>

          <div v-if="workout.workoutExercises.length">
            <table class="AddedExercises-table table table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Vingr.</th>
                  <th>
                    <span
                      class="glyphicon glyphicon-sort-by-attributes-alt"
                    ></span>
                  </th>
                  <th>
                    <span class="glyphicon glyphicon-stats"></span>
                  </th>
                  <th v-if="shouldShowExerciseTableCols.reps">
                    <span>R</span>
                    <button
                      v-if="lockedInput !== LockedInput.reps"
                      class="btn"
                      @click="lockedInput = LockedInput.reps"
                    >
                      <i class="glyphicon glyphicon-lock"></i>
                    </button>
                  </th>
                  <th v-if="shouldShowExerciseTableCols.weight">
                    <span>S</span>
                    <button
                      v-if="lockedInput !== LockedInput.weight"
                      class="btn"
                      @click="lockedInput = LockedInput.weight"
                    >
                      <i class="glyphicon glyphicon-lock"></i>
                    </button>
                  </th>
                  <th v-if="shouldShowExerciseTableCols.rpe">
                    <span>RPE</span>
                    <button
                      v-if="lockedInput !== LockedInput.rpe"
                      class="btn"
                      @click="lockedInput = LockedInput.rpe"
                    >
                      <i class="glyphicon glyphicon-lock"></i>
                    </button>
                  </th>
                  <th v-if="shouldShowExerciseTableCols.time">Laiks (sek)</th>
                  <th v-if="shouldShowExerciseTableCols.resistance_bands">
                    Gumijas pretestība
                  </th>
                  <th v-if="shouldShowExerciseTableCols.mode">Režīms</th>
                  <th v-if="shouldShowExerciseTableCols.incline_percent">
                    Slīpums (%)
                  </th>
                  <th v-if="shouldShowExerciseTableCols.pace">
                    Temps (min/km)
                  </th>
                  <th v-if="shouldShowExerciseTableCols.speed">
                    Ātrums (km/h)
                  </th>
                  <th v-if="shouldShowExerciseTableCols.pulse">Pulss</th>
                  <th v-if="shouldShowExerciseTableCols.height">
                    Augstums (cm)
                  </th>
                </tr>
              </thead>
              <VueDraggable
                v-model="workout.workoutExercises"
                group="addedExercises"
                handle=".drag-handle"
                item-key="_id"
                tag="tbody"
                @end="onDragEnd"
              >
                <template #item="{ element, index }">
                  <AddedExercise
                    :index="index"
                    :locked-input="lockedInput"
                    :should-show-columns="shouldShowExerciseTableCols"
                    :user-id="userId"
                    :workout="workout"
                    :workout-exercise="element"
                    @add-set="
                      addExercise(
                        element.exercise,
                        workout.workoutExercises.length,
                        index,
                      )
                    "
                    @remove="removeExercise(index)"
                    @update:locked-input="(value) => (lockedInput = value)"
                    @update:workout-exercise-property="
                      (property, value) =>
                        updateWorkoutExerciseProperty(index, property, value)
                    "
                  />
                </template>
              </VueDraggable>
            </table>
            <div class="next-lap-container">
              <!--              <button-->
              <!--                class="btn btn-default btn-sm w-100 w-full"-->
              <!--                @click="addAnotherLap"-->
              <!--              >-->
              <!--                Pievienot nākamo "apli"-->
              <!--              </button>-->
              <LoadingButton
                class="btn-info w-full"
                :loading="savingWorkoutAsDraft"
                @click="saveWorkoutAsDraft"
              >
                Saglabāt treniņu kā melnrakstu
              </LoadingButton>
              <LoadingButton
                v-if="!hasBackupWorkout"
                class="btn-info w-full"
                :loading="workoutSubmitting"
                @click="() => submitWorkout(true)"
              >
                Piešķirt kā REZERVES treniņu
              </LoadingButton>
              <LoadingButton
                class="w-full"
                :loading="workoutSubmitting"
                @click="submitWorkout"
              >
                Piešķirt kā ĪSTU treniņu
              </LoadingButton>
            </div>
          </div>
          <p v-else>Treniņam vēl nav pievienots neviens vingrojums...</p>
        </div>
      </div>

      <div
        id="danger-zone"
        aria-labelledby="danger-zone-tab"
        class="fade tab-pane"
        role="tabpanel"
      >
        <div class="p-16">
          <button class="btn btn-danger btn-sm" @click="deleteAllUserWorkouts">
            Dzēst visus lietotāja treniņus un ar tiem saistītos datus
          </button>
        </div>
      </div>

      <div
        id="chatgpt-parsing-demo"
        aria-labelledby="chatgpt-parsing-tab"
        class="fade tab-pane"
        role="tabpanel"
      >
        <ChatgptWorkoutParsing
          v-if="tags"
          :chatgpt-workout-text-prop="chatgptWorkoutText"
          :user-id="userId"
          @add-workout-exercise="handleChatgptParsingAddWorkoutExercise"
          @update:workout-description="
            (description) => (workout.description = description)
          "
        />
      </div>
    </div>

    <ExerciseCreationModal
      v-if="showCreateExerciseModal"
      :initial-name="exerciseNameFilter"
      @add-to-workout="addJustCreated"
      @close="showCreateExerciseModal = false"
    />
  </div>
</template>

<script setup lang="ts">
import ExerciseVM from '../models/ExerciseVM'
import WorkoutExerciseVM, {
  workoutExerciseForApi,
} from '../models/WorkoutExerciseVM'
import { computed, ref, watch } from 'vue'
import VueDraggable from 'vuedraggable'
import TagRepository from '../repositories/TagRepository'
import ExerciseRepository from '@common/repositories/ExerciseRepository'
import TemplateRepository from '../repositories/TemplateRepository'
import UserRepository from '../repositories/UserRepository'
import WorkoutRepository from '@common/repositories/WorkoutRepository'
import ExerciseCreationModal from '../components/ExerciseCreationModal.vue'
import LastWorkoutsTable from '../components/LastWorkoutsTable.vue'
import AddedExercise from '../components/AddedExercise.vue'
import LoadingButton from '../components/LoadingButton.vue'
import { LockedInput } from '../types/common'
import VueSelect from 'vue-select'
import { Template } from '../types/Template'
import { User } from '../types/User'
import { Workout } from '../types/Workout'
import ChatgptWorkoutParsing from './ChatgptWorkoutParsing.vue'
import { formatUserWorkoutsForChatgpt } from '@common/helpers/chatgpt'
import usePauseStore from '../stores/usePauseStore'
import { Tag } from '../types/Tag'

const { userId, chatgptWorkoutText = '' } = defineProps<{
  userId: number
  chatgptWorkoutText?: string
}>()

const pauseStore = usePauseStore()

type SelectOption = { value: string; label: string }

enum ExercisePopularity {
  popular = 'POPULAR',
  average = 'AVERAGE',
  rare = 'RARE',
}

const exercisePopularityToLabel = (popularity: ExercisePopularity): string =>
  ({ POPULAR: 'Populārs', AVERAGE: 'Vidēji populārs', RARE: 'Rets' })[
    popularity
  ]

const exercisePopularitySelectOptions1 = Object.values(ExercisePopularity).map(
  (v) => ({
    value: v,
    label: exercisePopularityToLabel(v as ExercisePopularity),
  }),
)

const exercises = ref<ExerciseVM[]>()
const templates = ref<Template[]>()
const user = ref<User>()
const userWorkouts = ref<Workout[]>()
const workout = ref<{
  student_id: number
  workoutExercises: WorkoutExerciseVM[]
  description: string | null
}>({ student_id: userId, workoutExercises: [], description: null })
const workoutSubmitting = ref(false)
const tagTypeSelectOptions = ref<SelectOption[]>()
const selectedTagTypes = ref<SelectOption[]>([])
const selectedTagGroups = ref<
  [
    Record<string, any>[],
    Record<string, any>[],
    Record<string, any>[],
    Record<string, any>[],
    Record<string, any>[],
  ]
>([[], [], [], [], []])
const exerciseNameFilter = ref('')
const exercisePopularitySelectOptions = ref(exercisePopularitySelectOptions1)
const selectedExercisePopularity = ref<
  (typeof exercisePopularitySelectOptions1)[0] | null
>(null)
const showCreateExerciseModal = ref(false)
const creatingExercise = ref(false)
const lockedInput = ref<LockedInput | null>(LockedInput.rpe)
const savingWorkoutAsDraft = ref(false)
const userWorkoutsLoading = ref(false)
const exerciseNumberToInsertNewExercisesAfter = ref(1)

const anyAddedExerciseHasAttribute = (attribute: keyof ExerciseVM) => {
  return workout.value.workoutExercises?.some((x) => x.exercise[attribute])
}
const loadTagTypeSelectOptions = async () => {
  const opts = await TagRepository.listTypeSelectOptions()
  tagTypeSelectOptions.value = Object.keys(opts).map((key) => ({
    value: key,
    label: opts[key],
  }))
}

const hasBackupWorkout = computed(() =>
  (userWorkouts.value ?? []).some((w) => w.is_backup),
)

const exerciseLoadParams = computed(() => ({
  tagIdGroups: selectedTagGroupsFlat.value.length
    ? selectedTagGroups.value.map((x) => x.map((y) => y.id))
    : null,
  tagTypes: selectedTagTypes.value.length
    ? selectedTagTypes.value.map((x) => x.value)
    : null,
  exercisePopularity: selectedExercisePopularity.value
    ? selectedExercisePopularity.value?.value
    : null,
  exerciseName: exerciseNameFilter.value,
}))

const activeExerciseRequestsCount = ref(0)

const exercisesLoading = computed(() => activeExerciseRequestsCount.value > 0)

let exerciseLoadAbortController: AbortController | null = null
const loadExercises = async (): Promise<void> => {
  if (exerciseLoadAbortController) exerciseLoadAbortController.abort()
  exerciseLoadAbortController = new AbortController()

  activeExerciseRequestsCount.value++
  try {
    exercises.value = await ExerciseRepository.list({
      params: exerciseLoadParams.value,
      signal: exerciseLoadAbortController.signal,
    })
  } catch (e: any) {
    console.info('Exercises search request cancelled!')
  } finally {
    activeExerciseRequestsCount.value--
  }
}
const loadTemplates = async () => {
  const templates = (await TemplateRepository.list()).map((template) => ({
    ...template,
    templateExercises: template.templateExercises.map((tempEx) => {
      return {
        id: tempEx.id,
        exercise: tempEx.exercise,
        weight: tempEx.weight ? parseFloat(tempEx.weight) : null,
        reps: tempEx.reps ? parseFloat(tempEx.reps) : null,
        time_seconds: tempEx.time_seconds
          ? parseFloat(tempEx.time_seconds)
          : null,
      }
    }),
  }))

  templates.value = templates
}
const loadUser = async () => {
  user.value = await UserRepository.get(userId)
}
const deleteAllUserWorkouts = async () => {
  try {
    await UserRepository.deleteAllWorkouts(userId)
  } catch (e: any) {
    console.error(e)
  } finally {
    window.location.reload()
  }
}
const loadUserWorkouts = async () => {
  userWorkoutsLoading.value = true
  if (userWorkouts.value) userWorkouts.value = []
  try {
    userWorkouts.value = await WorkoutRepository.ofUser(userId)
  } catch (e: any) {
    console.error(e)
  } finally {
    userWorkoutsLoading.value = false
  }
}

const removeExercise = (index: number) => {
  workout.value.workoutExercises.splice(index, 1)
  recalculateAddedExerciseSetNumbers()
}
const addTemplate = (template: Template) => {
  //@ts-expect-error TODO: fix this
  workout.value.workoutExercises.push(...template.templateExercises)
  recalculateAddedExerciseSetNumbers()
}

const addWorkoutExerciseAsCopy = (workoutExercise: WorkoutExerciseVM) => {
  const copyWithoutId = { ...workoutExercise }
  delete copyWithoutId._id
  workout.value.workoutExercises.push(
    new WorkoutExerciseVM(workoutExercise.exercise, copyWithoutId),
  )
}

const addExercise = (
  exercise: ExerciseVM,
  indexToAddAfter?: number,
  indexToCopy?: number,
) => {
  const toCopy =
    typeof indexToCopy === 'number'
      ? indexToCopy
      : typeof indexToAddAfter === 'number'
        ? indexToAddAfter
        : null

  const newExercise = new WorkoutExerciseVM(
    exercise,
    typeof toCopy === 'number'
      ? { ...workout.value.workoutExercises[toCopy] }
      : null,
  )

  if (workout.value.workoutExercises.length === 0) {
    workout.value.workoutExercises.push(newExercise)
  } else if (typeof indexToAddAfter === 'number') {
    workout.value.workoutExercises.splice(indexToAddAfter, 0, newExercise)
  } else if (exerciseNumberToInsertNewExercisesAfter.value >= 0) {
    let index = exerciseNumberToInsertNewExercisesAfter.value
    if (index > workout.value.workoutExercises.length) {
      index = workout.value.workoutExercises.length
    }
    workout.value.workoutExercises.splice(index, 0, newExercise)
    exerciseNumberToInsertNewExercisesAfter.value = ++index
  }
  recalculateAddedExerciseSetNumbers()
}

const addAnotherLap = () => {
  const exercisesToAdd: WorkoutExerciseVM[] = []
  for (let i = workout.value.workoutExercises.length - 1; i >= 0; i--) {
    const item = workout.value.workoutExercises[i]
    if (
      exercisesToAdd.length > 0 &&
      exercisesToAdd[exercisesToAdd.length - 1].exercise.id === item.exercise.id
    ) {
      break
    } else {
      exercisesToAdd.unshift(item)
    }
  }

  exercisesToAdd.forEach(addWorkoutExerciseAsCopy)
  recalculateAddedExerciseSetNumbers()
}

const submitWorkout = async (asBackup = false) => {
  workoutSubmitting.value = true
  try {
    await WorkoutRepository.create({
      ...workout.value,
      workoutExercises: workout.value.workoutExercises.map((we) =>
        workoutExerciseForApi(we),
      ),
      is_backup: asBackup,
    })
    window.location.replace('/assign')
  } catch (e: any) {
    console.error(e)
    if (e.response?.status === 422) {
      workoutSubmitting.value = false
    }
  }
}
const addJustCreated = async (exercise) => {
  addExercise(exercise)
  if (exerciseNameFilter.value.length >= 3) loadExercises()
}
const recalculateAddedExerciseSetNumbers = () => {
  const exerciseIdToSetCount = {}
  const exercisesClone: WorkoutExerciseVM[] = JSON.parse(
    JSON.stringify(workout.value.workoutExercises),
  ) as any // TODO: FIX THIS
  exercisesClone.forEach((x) => {
    x.sequenceNo =
      x.exercise.id in exerciseIdToSetCount
        ? exerciseIdToSetCount[x.exercise.id] + 1
        : 1
    exerciseIdToSetCount[x.exercise.id] = x.sequenceNo
  })
  workout.value.workoutExercises = exercisesClone
}
const onDragEnd = () => {
  recalculateAddedExerciseSetNumbers()
}
const saveWorkoutAsDraft = async () => {
  savingWorkoutAsDraft.value = true
  try {
    await WorkoutRepository.saveAsDraft({
      ...workout.value,
      workoutExercises: workout.value.workoutExercises.map((we) =>
        workoutExerciseForApi(we),
      ),
    })
    window.location.replace('/assign')
  } catch (e: any) {
    if (e.response?.status === 422) {
      console.error(e.response)
      workoutSubmitting.value = false
    }
  }
}

const selectedTagGroupsFlat = computed(() => selectedTagGroups.value.flat())
const shouldShowExerciseTableCols = computed(() => {
  const showReps = workout.value.workoutExercises?.some(
    (x) => x.exercise.has_reps,
  )
  const showWeight = workout.value.workoutExercises?.some(
    (x) => x.exercise.has_weight,
  )
  const showTime = workout.value.workoutExercises?.some(
    (x) => x.exercise.has_time || x.exercise.is_pause,
  )
  return {
    reps: showReps,
    weight: showWeight,
    time: showTime,
    rpe: showWeight || showReps || showTime,
    resistance_bands: anyAddedExerciseHasAttribute('has_resistance_bands'),
    mode: anyAddedExerciseHasAttribute('has_mode'),
    incline_percent: anyAddedExerciseHasAttribute('has_incline_percent'),
    pace: anyAddedExerciseHasAttribute('has_pace'),
    speed: anyAddedExerciseHasAttribute('has_speed'),
    distance: anyAddedExerciseHasAttribute('has_distance'),
    pulse: anyAddedExerciseHasAttribute('has_pulse'),
    height: anyAddedExerciseHasAttribute('has_height'),
  }
})

watch(selectedTagGroupsFlat, (n, o) => {
  if (!o || n.length > o.length) loadExercises()
})

watch(selectedTagTypes, loadExercises)

watch(selectedExercisePopularity, loadExercises)

watch(exerciseNumberToInsertNewExercisesAfter, (n) => {
  if (n < 0) exerciseNumberToInsertNewExercisesAfter.value = 1
  if (n > workout.value.workoutExercises.length) {
    exerciseNumberToInsertNewExercisesAfter.value =
      workout.value.workoutExercises.length
  }
})

loadTemplates()
loadUser()
loadUserWorkouts()
loadTagTypeSelectOptions()

watch(exerciseNameFilter, (n) => {
  if (n.length >= 3) loadExercises()
})

const exercisesCollapsed = ref(false)

const updateWorkoutExerciseProperty = <
  T extends keyof (typeof workout.value.workoutExercises)[number],
>(
  index: number,
  property: T,
  value: (typeof workout.value.workoutExercises)[number][T],
) => {
  if (!workout.value.workoutExercises[index]) return
  workout.value.workoutExercises[index][property] = value
}

const zIndexStyle = computed(
  () => `z-index: ${exercisesLoading.value ? '-1' : 'auto'}`,
)

const handleChatgptParsingAddWorkoutExercise = (
  newExercise: WorkoutExerciseVM,
) => {
  if (workout.value.workoutExercises.length === 0) {
    workout.value.workoutExercises.push(newExercise)
  } else if (exerciseNumberToInsertNewExercisesAfter.value >= 0) {
    let index = exerciseNumberToInsertNewExercisesAfter.value
    if (index > workout.value.workoutExercises.length) {
      index = workout.value.workoutExercises.length
    }
    workout.value.workoutExercises.splice(index, 0, newExercise)
    exerciseNumberToInsertNewExercisesAfter.value = ++index
  }
  recalculateAddedExerciseSetNumbers()
}

const lastWorkoutsTextForChatgpt = computed(() => {
  if (!userWorkouts.value) return null
  return formatUserWorkoutsForChatgpt(userWorkouts.value)
})

const tags = ref<Tag[]>()
;(async () => {
  try {
    tags.value = await TagRepository.list()
  } catch (e) {
    console.error('Error while loading tags: ', e)
  }
})()
</script>

<style scoped lang="scss">
th {
  padding: 4px !important;
}

.name-filter-container {
  display: flex;
  gap: 8px;
}

.pl-0 {
  padding-left: 0;
}

.selected-tag-group {
  border-top: 0;
  border-bottom: 0;
  text-align: center;
}

.loader {
  height: 80px;
  width: 80px;
  margin: 25% auto auto auto;
  border-color: green;
  border-width: 8px;
  border-top-color: gainsboro;
}

.exercise-creation-container {
  text-align: center;
  font-size: 18px;
  margin-bottom: 0;
}

.mr-8 {
  margin-right: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.insert-after-label {
  display: flex;
  justify-content: end;
  gap: 8px;
  align-items: center;
}

.insert-after-input {
  width: 70px;
  margin-left: auto;
}

.exercise-li {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.pause-title {
  text-align: center;
  font-size: 18px;
  margin-bottom: 0;
}

.pause-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
}

.next-lap-container {
  margin-top: 16px;
  display: flex;
  gap: 16px;
}

.w-full {
  width: 100%;
}

.p-16 {
  padding: 16px;
}
</style>
