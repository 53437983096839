<template>
  <button class="btn btn-primary" :disabled="loading" @click="emit('click')">
    <span><slot></slot></span>
    <span
      class="loader"
      :style="{ visibility: loading ? 'visible' : 'hidden' }"
    ></span>
  </button>
</template>

<script setup lang="ts">
const { loading } = defineProps<{ loading: boolean }>()
const emit = defineEmits<{ (e: 'click'): void }>()
</script>

<style scoped lang="scss">
button {
  display: flex;
  gap: 8px;
  margin: auto;
  justify-content: center;
}
</style>
