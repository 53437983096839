<template>
  <div>
    <div v-if="inProgress" style="background: white; padding: 16px">
      <h4 style="color: black">
        Tavs personalizētais treniņu plāns jau veidojas! Neaizver šo lapu!
      </h4>
      <div style="text-align: center">
        <div class="loader" style="margin: auto"></div>
      </div>
      <TimeProgressBar
        ref="timer"
        :time-seconds="averageWorkoutCreationTimeSeconds"
      />
    </div>
    <div v-else-if="error" style="background: white; padding: 16px">
      <h4 style="color: red">Kaut kas nogāja greizi!</h4>
      <p>{{ error }}</p>
    </div>
    <div v-else-if="workoutPlan">
      <div class="sticky">
        <p style="font-size: 20px; margin-top: 16px">
          Treniņu plāns ir nosūtīts uz Tavu e-pastu!
        </p>
        <form action="/registration/profile-creation-start" method="POST">
          <input name="_csrf" type="hidden" :value="getCsrfToken()" />
          <input
            v-for="[key, value] in searchParams"
            :key="key"
            :name="key"
            type="hidden"
            :value="value"
          />
          <input name="workout_plan" type="hidden" :value="workoutPlan" />
          <div class="wrapper">
            <button class="btn-brand btn-brand-green">Registreties</button>
          </div>
        </form>
      </div>
      <VueMarkdown :source="workoutPlan" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { nextTick, ref } from 'vue'
import TimeProgressBar from '../components/TimeProgressBar.vue'
import VueMarkdown from 'vue-markdown-render'
import {
  generateCompletion,
  getLatestChatModel,
} from '@common/repositories/OpenAIRepository'
import { getCsrfToken } from '@client/helpers/Global'
import LeadRepository, { LeadForm } from '@client/repositories/LeadRepository'

const props = defineProps<{
  promptText: string
  leadData: Pick<LeadForm, 'name' | 'email'>
}>()

const inProgress = ref(false)
const workoutPlan = ref<string | null>(null)
const error = ref(false)
const apiErrorMessage = ref<string | null>(null)

const timer = ref<{ startProgress: () => void } | null>(null)

const createWorkoutPlan = async () => {
  inProgress.value = true

  await nextTick()
  timer.value?.startProgress()

  apiErrorMessage.value = null

  const model = await getLatestChatModel('gpt-4')
  if (!model) return
  const [text, errorMessage] = await generateCompletion(model.id, [
    { role: 'system', content: props.promptText },
  ])

  if (text) {
    workoutPlan.value = text
    await LeadRepository.create({ ...props.leadData, workout_plan: text })
  } else if (errorMessage) {
    apiErrorMessage.value = JSON.stringify(errorMessage)
  }

  inProgress.value = false
}
createWorkoutPlan()

const averageWorkoutCreationTimeSeconds = 15

const searchParams = new URLSearchParams(location.search)
</script>

<style lang="scss" scoped>
.client-auto-workout {
  text-align: center;
}

textarea {
  margin-bottom: 16px;
  width: 100%;
}

.wrapper {
  text-align: center;
  padding-bottom: 16px;
}

.sticky {
  position: sticky;
  top: 68px;
  background: rgba(255, 255, 255, 0.8);
  margin-left: -31px;
  margin-right: -31px;
  padding: 4px 32px 0 32px;
  margin-bottom: 16px;
}
</style>
