import axios from 'axios'
import Repository from './Repository'

export type ClientSettings = {
  id: number
  user_id: number
  auto_warmup_and_cooldown: boolean
  default_equipment_set_id: number | null
  should_use_equipment_set: boolean
}

export default class ClientSettingsRepository extends Repository {
  static get baseUrl() {
    return '/client-settings'
  }

  static async getByUser(userId: number) {
    return (
      await axios.get<ClientSettings>(`${this.baseUrl}/api-get-by-user`, {
        params: { userId },
      })
    ).data
  }

  static update(data: Partial<ClientSettings>) {
    return axios.post(`${this.baseUrl}/api-update`, data)
  }
}
