<template>
  <div class="client-analysis-section text-center">
    <div v-if="analysisText" class="result-container">
      <h4>Analīze par Tevi:</h4>
      <p>{{ analysisText }}</p>
    </div>
    <div v-else-if="!inProgress">
      <button class="btn-brand btn-brand-green" @click="handleSubmit">
        Analizet manu profilu
      </button>
    </div>
    <div v-else-if="inProgress" style="background: white; padding: 16px">
      <h4 style="color: black">
        Analīze par Tevi jau veidojas! Neaizver šo lapu!
      </h4>
      <div style="text-align: center">
        <div class="loader" style="margin: auto"></div>
      </div>
      <TimeProgressBar
        ref="timer"
        :time-seconds="averageAnalysisCreationTimeSeconds"
      />
    </div>
    <div v-else-if="error" style="background: white; padding: 16px">
      <h4 style="color: red">Kaut kas nogāja greizi!</h4>
    </div>
  </div>
</template>

<script setup lang="ts">
import { nextTick, ref } from 'vue'

import TimeProgressBar from '../components/TimeProgressBar.vue'
import VariousPromptsRepository from '@common/repositories/VariousPromptsRepository'
import {
  generateCompletion,
  getLatestChatModel,
} from '@common/repositories/OpenAIRepository'

const props = defineProps<{ userId: number }>()

const inProgress = ref(false)
const error = ref(false)

const timer = ref<{ startProgress: () => void } | null>(null)

const analysisText = ref<string | null>(null)

const handleSubmit = async () => {
  inProgress.value = true

  await nextTick()
  timer.value?.startProgress()

  try {
    const [model, promptText] = await Promise.all([
      getLatestChatModel('gpt-4'),
      VariousPromptsRepository.createForUser({
        key: 'analyse_user',
        userId: props.userId,
      }),
    ])
    if (!model) {
      throw new Error('Model not found')
    }

    const [text, error] = await generateCompletion(model.id, [
      { role: 'user', content: promptText.result },
    ])

    if (text) {
      analysisText.value = text
    }
  } catch (e) {
    console.error(e)
    error.value = true
  } finally {
    inProgress.value = false
  }
}

const averageAnalysisCreationTimeSeconds = 45
</script>

<style lang="scss" scoped>
.client-analysis-section {
  margin-top: 24px;
}

.result-container {
  background: white;
  color: black;
  padding: 8px 4px;
}
</style>
