import { createApp } from 'vue'
import 'vue-select/dist/vue-select.css'
import ClientGptChat from './apps/ClientGptChat.vue'
import ClientAutoWorkout from './apps/ClientAutoWorkout.vue'
import ClientWorkoutPlanCreation from './apps/ClientWorkoutPlanCreation.vue'
import ClientAnalysisSection from './apps/ClientAnalysisSection.vue'
import { createPinia } from 'pinia'
import jquery from 'jquery'
import ClientSingleTimer from './apps/ClientSingleTimer.vue'
import ClientDoubleTimer from './apps/ClientDoubleTimer.vue'
import { VueQueryPlugin } from '@tanstack/vue-query'

declare global {
  interface Window {
    $: typeof jquery
    userId: number
    promptId: number
    hasAnyWorkouts: boolean
  }
}

const initializeApp = (
  ClientComponent: Parameters<typeof createApp>[0],
  rootProps?: Parameters<typeof createApp>[1],
  usePinia = false,
) => {
  let app = createApp(ClientComponent, rootProps)
  if (usePinia) app = app.use(createPinia())
  app.use(VueQueryPlugin)
  return app
}

const appInitializers = {
  'client-gpt-chat': (rootProps: Parameters<typeof createApp>[1]) =>
    initializeApp(ClientGptChat, rootProps),
  'client-auto-workout': (rootProps: Parameters<typeof createApp>[1]) =>
    initializeApp(ClientAutoWorkout, rootProps, true),
  'client-analysis-section': (rootProps: Parameters<typeof createApp>[1]) =>
    initializeApp(ClientAnalysisSection, rootProps),
  'client-single-timer': (rootProps: Parameters<typeof createApp>[1]) =>
    initializeApp(ClientSingleTimer, rootProps),
  'client-double-timer': (rootProps: Parameters<typeof createApp>[1]) =>
    initializeApp(ClientDoubleTimer, rootProps),
  'client-workout-plan-creation': (
    rootProps: Parameters<typeof createApp>[1],
  ) => initializeApp(ClientWorkoutPlanCreation, rootProps),
}

const parseDataset = (dataset: DOMStringMap) => {
  const parsed: Record<string, unknown> = {}
  Object.entries(dataset).forEach(([key, value]) => {
    try {
      parsed[key] = value ? JSON.parse(value) : value
    } catch (e) {
      parsed[key] = value
    }
  })
  return parsed
}

for (const id in appInitializers) {
  const element = document.getElementById(id)
  if (element) {
    appInitializers[id](parseDataset(element.dataset)).mount(element)
  }
}
