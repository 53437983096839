import Repository from '@common/repositories/Repository'
import axios from 'axios'

export type VariousPrompts = {
  id: number
  find_exercise_tags: string
  find_most_similar_exercise: string
}

export default class VariousPromptsRepository extends Repository {
  static get baseUrl() {
    return '/various-prompts'
  }

  static async get() {
    return (await axios.get(`${this.baseUrl}/api-get`)).data as VariousPrompts
  }

  static async createForUser(
    params: { userId: number } & (
      | { key: 'analyse_user' | 'choose_default_workout_prompt' }
      | {
          key: 'find_exercise_tags'
          variables: { exercise_name: string }
        }
      | {
          key: 'choose_specific_workout_prompt'
          variables: {
            last_workouts_text: string | null
            user_feeling_before_workout: string
          }
        }
      | {
          key: 'find_most_similar_exercise'
          variables?: Partial<{
            exercise_name: string
            database_exercises: string
          }>
        }
    ),
  ): Promise<{
    model: Record<string, any>
    user: Record<string, any>
    result: string
  }> {
    return (await axios.get(`${this.baseUrl}/api-create-for-user`, { params }))
      .data
  }
}
