import WorkoutExerciseVM from '../models/WorkoutExerciseVM'

export default class RestTimeCalculator {
  private static rpeToRestTimeSeconds(rpe: number | string) {
    const rpeNumber = typeof rpe === 'string' ? parseFloat(rpe) : rpe
    return (
      (0.75516 * rpeNumber ** 3 -
        5.65824 * rpeNumber ** 2 +
        17.62308 * rpeNumber -
        6.61188) /
      1.6
    )
  }
  private static defaultRestTime = this.rpeToRestTimeSeconds(7.5)
  private static maxRecoveryTime = 285
  private static timeRecoveredForEachExerciseSeconds = 30
  private static multiplierForNonMatchingExercises = 0.1

  private static getTotalRecoveryTimeForWorkoutExercise(
    workoutExercise: WorkoutExerciseVM
  ) {
    return workoutExercise.rpe
      ? this.rpeToRestTimeSeconds(workoutExercise.rpe.value)
      : this.defaultRestTime
  }

  public static getTirednessPercentageForWorkoutExercise(
    workoutExercises: WorkoutExerciseVM[],
    workoutExercise: WorkoutExerciseVM,
    exerciseIndex: number
  ) {
    const reduceTime = (secondsToSubtract: number) => {
      timeToFullRecoverySeconds -= secondsToSubtract
      if (timeToFullRecoverySeconds < 0) timeToFullRecoverySeconds = 0
    }

    let timeToFullRecoverySeconds = 0

    workoutExercises.slice(0, exerciseIndex).forEach((we) => {
      if (we.exercise.is_pause && we.time_seconds) reduceTime(we.time_seconds)
      else {
        const multiplier =
          workoutExercise.exercise.id === we.exercise.id
            ? 1
            : this.multiplierForNonMatchingExercises
        const setRecoveryTime = this.getTotalRecoveryTimeForWorkoutExercise(we)

        timeToFullRecoverySeconds += setRecoveryTime * multiplier
        reduceTime(this.timeRecoveredForEachExerciseSeconds)
      }
    })

    return (timeToFullRecoverySeconds / this.maxRecoveryTime) * 100
  }
}
