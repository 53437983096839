<template>
  <div>
    <div v-if="type === 'text'">
      <div class="form-group">
        <label class="control-label">{{ label }}</label>
        <input v-model="internalValue" class="form-control" type="text" />
      </div>
    </div>
    <div v-else-if="type === 'textarea' && typeof internalValue !== 'boolean'">
      <div class="form-group">
        <label class="control-label">{{ label }}</label>
        <textarea v-model="internalValue" class="form-control"></textarea>
      </div>
    </div>
    <div v-else-if="type === 'checkbox' && typeof internalValue === 'boolean'">
      <div class="form-group">
        <label>
          <input v-model="internalValue" type="checkbox" />
          <span>{{ label }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue'

const props = defineProps<{
  modelValue: string | number | boolean | undefined | null
  type: 'text' | 'textarea' | 'checkbox'
  label: string
}>()

const emit = defineEmits<{
  (e: 'input', value: string | number | boolean | undefined): void
}>()

const internalValue = ref(
  props.modelValue === null ? undefined : props.modelValue
)

watchEffect(() => emit('input', internalValue.value))
</script>
