export enum LockedInput {
  weight = 'weight',
  reps = 'reps',
  rpe = 'rpe',
}

export enum AverageAbilityType {
  '1RM' = '1RM',
  'REPS_OR_TIME' = 'REPS_OR_TIME',
  'KGSEC' = 'KGSEC',
}

export type AverageAbility = {
  type: AverageAbilityType
  ability: number
}
