<template>
  <form action="" @submit.prevent="createPromptText">
    <input
      v-model="userId"
      class="form-control"
      min="1"
      name="user-id-input"
      placeholder="Lietotāja ID"
      type="number"
    />
    <button class="btn btn-primary" :disabled="!userId || creatingPromptText">
      <span
        v-if="creatingPromptText"
        aria-hidden="true"
        class="spinner-border spinner-border-sm"
        role="status"
      ></span>
      Izveidot uzvedni
    </button>
  </form>

  <Modal
    v-if="promptText"
    title="Uzvedne lietotājam"
    @close="promptText = null"
  >
    <form action="" @submit.prevent="handleSubmit">
      <div
        contenteditable="true"
        style="width: 100%"
        @input="(e) => (promptText = (e.target as HTMLElement).innerHTML)"
        v-html="promptTextCopy"
      ></div>
      <button class="btn btn-primary" :disabled="creatingWorkout">
        <span
          v-if="creatingWorkout"
          aria-hidden="true"
          class="spinner-border spinner-border-sm"
          role="status"
        ></span>
        Izveidot treniņu
      </button>
      <p v-if="apiErrorMessage" class="text-danger">{{ apiErrorMessage }}</p>
    </form>
  </Modal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import Modal from 'common/components/Modal.vue'
import { ChatgptWorkoutCreator } from '@common/helpers/chatgpt'

const props = defineProps<{ promptId: number }>()

const userId = ref<number>()

const creatingPromptText = ref(false)
const creatingWorkout = ref(false)
const apiErrorMessage = ref<string | null>(null)

const promptText = ref<string | null>(null)
const promptTextCopy = ref<string | null>(null)

const chatgptWorkoutCreator = computed(() => {
  if (!userId.value) return
  return new ChatgptWorkoutCreator(userId.value)
})

const createPromptText = async () => {
  if (!chatgptWorkoutCreator.value) return
  creatingPromptText.value = true
  try {
    promptText.value = promptTextCopy.value =
      await chatgptWorkoutCreator.value!.generatePromptText({
        promptId: props.promptId,
      })
  } catch (e) {
    console.error(e)
  } finally {
    creatingPromptText.value = false
  }
}

const handleSubmit = async () => {
  if (!chatgptWorkoutCreator.value || !promptText.value) return

  creatingWorkout.value = true
  apiErrorMessage.value = null
  const [text, errorMessage] =
    await chatgptWorkoutCreator.value.createChatgptWorkoutText(promptText.value)
  if (text) {
    window.location.href = `/workout/index?userId=${
      userId.value
    }&chatgptWorkoutText=${encodeURIComponent(text)}`
  } else if (errorMessage) {
    apiErrorMessage.value = errorMessage
  }

  creatingWorkout.value = false
}
</script>

<style scoped lang="scss">
label {
  min-width: 90px;
}

button {
  margin-top: 8px;
}
</style>
