<template>
  <div class="client-gpt-chat">
    <button class="btn btn-info" @click="showChatModal = true">Treneris</button>
  </div>

  <Modal v-if="showChatModal" title="Treneris" @close="showChatModal = false">
    <div>
      <p>
        Ko teiksi? (Treneris atbildēs uz visiem jautājumiem, bet pagaidām vēl
        neapzinās Tavu treniņu. Uzkod konkrētus jautājumus, minot vingrojuma
        nosaukumus, piem. “Vai pietupjoties ceļiem jāiet pāri pirkstgaliem?“ vai
        “Man nedaudz sāp mugura taisot presītes“. Atbilde būs tūlītēja, bet var
        aizņemt līdz 60sec.)
      </p>
      <textarea v-model="chatGptPrompt" cols="30" rows="10"></textarea>
      <div class="text-center">
        <button
          class="btn btn-primary mb-2 mt-2"
          :disabled="completionLoading"
          @click="submitPrompt"
        >
          Nosūtīt
        </button>
        <div
          v-if="completionLoading"
          class="answer-spinner spinner-border"
          role="status"
        ></div>
        <div v-if="chatGptResponse">
          <p>Atbilde: {{ chatGptResponse }}</p>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Modal from 'common/components/Modal.vue'
import { ref } from 'vue'
import {
  generateCompletion,
  getLatestChatModel,
} from '@common/repositories/OpenAIRepository'
import { Model } from '@common/types/OpenAI'

const showChatModal = ref(false)
const chatGptPrompt = ref('')
const chatGptResponse = ref<string | null>('')
const completionLoading = ref(false)

const modelToUse = ref<Model | null>(null)

const submitPrompt = async () => {
  if (!modelToUse.value) {
    modelToUse.value = await getLatestChatModel('gpt-4')
  }
  if (!modelToUse.value) {
    console.error('no model to use!')
    return
  }

  completionLoading.value = true
  const [text, e] = await generateCompletion(modelToUse.value.id, [
    {
      role: 'system',
      content:
        'Be as concise as possible while mentioning the most important. Your responses should not be longer than 100 words.',
    },
    { role: 'user', content: chatGptPrompt.value },
  ])
  if (text) {
    chatGptResponse.value = text
  } else if (e) {
    console.error(e)
  }
  completionLoading.value = false
}
</script>

<style scoped lang="scss">
.client-gpt-chat {
  position: fixed;
  display: inline-block;
  top: 8px;
  right: 64px;
  z-index: 1031;
}

.answer-spinner {
  display: block;
  margin: 16px auto;
}
</style>
