import Repository from '@common/repositories/Repository'
import axios from 'axios'

export type LeadForm = {
  name: string
  email: string
  workout_plan: string
}

export default class LeadRepository extends Repository {
  static get baseUrl() {
    return '/lead'
  }

  static async create(data: LeadForm) {
    return await axios.post(`${this.baseUrl}/api-create`, data)
  }
}
