export default class RepMaxCalculator {
  public static calculateOneRepMax(_weight: number, _reps: number): number {
    throw new Error('Not implemented.')
  }
  static calculatePercentageOfOneRepMax(_rpe: number, _reps: number): number {
    throw new Error('Not implemented.')
  }
  static calculateReps(_rpe: number, _percOf1RM: number): number {
    throw new Error('Not implemented.')
  }
  static calculateRpe(_reps: number, _percOf1RM: number): number {
    throw new Error('Not implemented.')
  }
  static calculateMaxReps(_rpe: number, _reps: number): number {
    throw new Error('Not implemented.')
  }
}
