import Repository from '@common/repositories/Repository'
import axios from 'axios'

type WorkoutPrompt = {
  author_id: number
  id: number
  name: string | null
  text: string
  sample_output: string
  score: number
  notes: string | null
  is_deleted: boolean
}

export default class WorkoutPromptRepository extends Repository {
  static get baseUrl() {
    return '/workout-prompt'
  }

  static async createForUser(data: Record<string, any>): Promise<{
    model: Record<string, unknown>
    user: Record<string, unknown>
    result: string
  }> {
    return (await axios.post(`${this.baseUrl}/api-create-for-user`, data)).data
  }

  static async getByName(name: string) {
    return (
      await axios.get<WorkoutPrompt | null>(`${this.baseUrl}/api-get-by-name`, {
        params: { name },
      })
    ).data
  }
}
