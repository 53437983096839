<template>
  <Modal title="Vingrojuma izveidošana" @close="emit('close')">
    <div>
      <FormInput
        v-model="exercise.name"
        :label="attributeLabels['name']"
        type="text"
      />
      <FormInput
        v-model="exercise.description"
        :label="attributeLabels['description']"
        type="textarea"
      />
      <FormInput
        v-model="exercise.video"
        :label="attributeLabels['video']"
        type="text"
      />
      <FormInput
        v-model="exercise.technique_video"
        :label="attributeLabels['technique_video']"
        type="text"
      />
      <FormInput
        v-model="exercise.single_rep_description"
        :label="attributeLabels['single_rep_description']"
        type="text"
      />
      <FormInput
        v-model="exercise.needs_evaluation"
        :label="attributeLabels['needs_evaluation']"
        type="checkbox"
      />
      <label>{{ attributeLabels['is_bodyweight'] }}</label>
      <VueSelect
        v-model="exercise.is_bodyweight"
        label="label"
        :options="[
          { value: false, label: 'Nē' },
          { value: true, label: 'Jā' },
        ]"
      />
      <h3>Parametri, kurus jāievada piešķirot vingrojumu</h3>
      <div class="params-container">
        <div>
          <FormInput
            v-model="exercise.has_reps"
            :label="attributeLabels['has_reps']"
            type="checkbox"
          />
          <FormInput
            v-model="exercise.has_weight"
            :label="attributeLabels['has_weight']"
            type="checkbox"
          />
          <FormInput
            v-model="exercise.has_time"
            :label="attributeLabels['has_time']"
            type="checkbox"
          />
        </div>
        <div>
          <FormInput
            v-model="exercise.has_resistance_bands"
            :label="attributeLabels['has_resistance_bands']"
            type="checkbox"
          />
          <FormInput
            v-model="exercise.has_mode"
            :label="attributeLabels['has_mode']"
            type="checkbox"
          />
          <FormInput
            v-model="exercise.has_height"
            :label="attributeLabels['has_height']"
            type="checkbox"
          />
        </div>
        <div>
          <FormInput
            v-model="exercise.has_incline_percent"
            :label="attributeLabels['has_incline_percent']"
            type="checkbox"
          />
          <FormInput
            v-model="exercise.has_pace"
            :label="attributeLabels['has_pace']"
            type="checkbox"
          />
          <FormInput
            v-model="exercise.has_speed"
            :label="attributeLabels['has_speed']"
            type="checkbox"
          />
          <FormInput
            v-model="exercise.has_distance"
            :label="attributeLabels['has_distance']"
            type="checkbox"
          />
          <FormInput
            v-model="exercise.has_pulse"
            :label="attributeLabels['has_pulse']"
            type="checkbox"
          />
        </div>
      </div>
      <div class="form-group">
        <label class="control-label" for="interchangeable-exercises">
          Aizvietojamie vingrojumi
        </label>
        <select
          id="interchangeable-exercises"
          aria-invalid="false"
          aria-required="true"
          class="form-control"
          multiple
          name="interchangeableExercises[]"
        ></select>
      </div>
    </div>
    <div class="button-container">
      <button class="btn" type="button" @click="emit('close')">Atcelt</button>
      <button
        class="btn btn-primary"
        :disabled="!exercise.name || !exercise.name.length || isLoading"
        @click="submitWithoutAdding"
      >
        Izveidot
      </button>
      <button
        class="btn btn btn-success"
        :disabled="!exercise.name || !exercise.name.length || isLoading"
        @click="submitAndAddToWorkout"
      >
        Izveidot un piešķirt treniņam
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue'
import ExerciseRepository from '@common/repositories/ExerciseRepository'
import ExerciseVM from '../models/ExerciseVM'
import Modal from 'common/components/Modal.vue'
import FormInput from './FormInput.vue'
import VueSelect from 'vue-select'

type ExerciseHere = Omit<Partial<ExerciseVM>, 'is_bodyweight'> & {
  interchangeableExercises?: string | string[] | number
  is_bodyweight: { text: string; value: boolean } | boolean | null
}

const { initialName = '' } = defineProps<{ initialName: string }>()
const emit = defineEmits<{
  (e: 'close'): void
  (e: 'add-to-workout', exercise: ExerciseHere)
}>()

const exercise = reactive<ExerciseHere>({
  name: initialName,
  description: null,
  video: null,
  technique_video: null,
  single_rep_description: null,
  needs_evaluation: true,
  is_bodyweight: null,
  has_reps: true,
  has_weight: true,
  has_time: false,
  has_resistance_bands: false,
  has_mode: false,
  has_incline_percent: false,
  has_pace: false,
  has_speed: false,
  has_distance: false,
  has_pulse: false,
  has_height: false,
})

const attributeLabels = {
  name: 'Nosaukums',
  description: 'Apraksts',
  video: 'Video',
  technique_video: 'Tehnikas video',
  single_rep_description: 'Vienas reizes apraksts',
  needs_evaluation: 'Jānovērtē',
  is_bodyweight: 'Ķermeņa svara vingrojums',
  has_reps: 'Reizes',
  has_weight: 'Svars (kg)',
  has_time: 'Laiks (s)',
  has_resistance_bands: 'Pretestības gumijas',
  has_mode: 'Režīms',
  has_incline_percent: 'Slīpums (%)',
  has_pace: 'Temps',
  has_speed: 'Ātrums (km/h)',
  has_distance: 'Distance (m)',
  has_pulse: 'Pulss',
  has_height: 'Augstums (cm)',
} as const satisfies Partial<Record<keyof typeof exercise, string>>

const isLoading = ref(false)

onMounted(() => {
  window.setupInterchangeableExerciseSelects()
})

const submit = async () => {
  isLoading.value = true
  try {
    const data = { ...exercise }
    if (
      data.is_bodyweight &&
      typeof data.is_bodyweight === 'object' &&
      'value' in data.is_bodyweight
    ) {
      data.is_bodyweight = data.is_bodyweight.value
    }
    const interchangeableExercisesSelect = window.$(
      'select#interchangeable-exercises',
    )
    if (interchangeableExercisesSelect) {
      data.interchangeableExercises = interchangeableExercisesSelect
        //@ts-expect-error this comes from global select2
        .select2()
        .val()
    }

    return await ExerciseRepository.create(data)
  } catch (e: any) {
    console.error(e)
  } finally {
    isLoading.value = false
  }
}
const submitWithoutAdding = async () => {
  await submit()
  emit('close')
}
const submitAndAddToWorkout = async () => {
  const exercise = await submit()
  emit('add-to-workout', exercise)
  emit('close')
}
</script>

<style scoped lang="scss">
.params-container {
  display: flex;
  gap: 32px;
}

.button-container {
  display: flex;
  gap: 8px;
  justify-content: center;
}
</style>
