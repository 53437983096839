import ExerciseVM from './ExerciseVM'
import { guidGenerator, Rpe } from '../helpers/Global'

export default class WorkoutExerciseVM {
  _id?: string | null
  exercise: ExerciseVM
  sequenceNo: number | null = null
  reps: number | null = null
  weight: number | null = null
  time_seconds: number | null = null
  resistance_bands: { value: number; label: string } | number | null = null
  mode: number | null = null
  incline_percent: number | null = null
  pace_min_km: number | null = null
  speed_km_h: number | null = null
  distance_m: number | null = null
  pulse: number | null = null
  height: number | null = null
  rpe?: { value: Rpe; label: Rpe } | null = null
  assigned_rpe: Rpe | null = null
  is_skipped = false
  skipped_reason: string | null = null
  tiredness_percentage = 0
  user_notes: string | null = null

  constructor(
    exercise: ExerciseVM,
    setToCopy: Partial<WorkoutExerciseVM> | null
  ) {
    this.exercise = exercise
    if (setToCopy) Object.assign(this, setToCopy)
    this._id = guidGenerator()
  }
}

export const workoutExerciseForApi = (workoutExercise: WorkoutExerciseVM) => {
  const copy = { ...workoutExercise }
  copy.assigned_rpe = copy.rpe?.value ?? null
  delete copy.rpe
  if (copy.resistance_bands && typeof copy.resistance_bands === 'object') {
    copy.resistance_bands = copy.resistance_bands.value
  }
  if (!copy.exercise.has_weight) {
    copy.weight = null
  }
  copy.tiredness_percentage = Math.round(copy.tiredness_percentage)

  return copy
}

export const resistanceBandToWeight = (resistanceBand: number) => {
  switch (resistanceBand) {
    case 1:
      return 6
    case 2:
      return 10
    case 3:
      return 16
    default:
      return 0
  }
}
