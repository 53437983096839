import { getCsrfToken } from 'trainer/src/helpers/Global'
import axios from 'axios'

axios.interceptors.request.use((config) => {
  config.headers['X-CSRF-Token'] = getCsrfToken()
  return config
})

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default class Repository {
  static get baseUrl(): string {
    throw new Error('baseUrl getter must be defined!')
  }

  static get baseGetUrl() {
    return `${this.baseUrl}/api-get`
  }

  static get baseCreateUrl() {
    return `${this.baseUrl}/api-create`
  }

  static get baseUpdateUrl() {
    return `${this.baseUrl}/api-update`
  }

  static get baseDeleteUrl() {
    return `${this.baseUrl}/api-delete`
  }
}
