import ExerciseRepository from '@common/repositories/ExerciseRepository'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import WorkoutExerciseVM from '../models/WorkoutExerciseVM'
import RpeCalculatorForWeight from '../helpers/RpeCalculatorForWeight'
import { Rpe } from '../helpers/Global'
import { AverageAbility, AverageAbilityType } from '../types/common'
import { RpeCalculatorForRepsOrTime } from '../helpers/RpeCalculatorForRepsOrTime'

const useAverageAbilityStore = defineStore('average-ability-cache', () => {
  const cache = ref(new Map<number, AverageAbility>())
  const getExerciseAbility = async (exerciseId: number, userId: number) => {
    if (!cache.value.has(exerciseId)) {
      cache.value.set(
        exerciseId,
        await ExerciseRepository.getAverageAbility(exerciseId, userId),
      )
    }
    return cache.value.get(exerciseId)
  }

  const setExerciseAbility = (
    workoutExercise: WorkoutExerciseVM & { rpe: { value: Rpe; label: Rpe } },
  ) => {
    let ability: AverageAbility | null = null
    if (workoutExercise.weight && workoutExercise.reps) {
      ability = {
        type: AverageAbilityType['1RM'],
        ability:
          workoutExercise.weight /
          RpeCalculatorForWeight.calculatePercentageOfOneRepMax(
            workoutExercise.rpe.value,
            workoutExercise.reps,
          ),
      }
    } else if (workoutExercise.reps) {
      ability = {
        type: AverageAbilityType.REPS_OR_TIME,
        ability: Math.round(
          RpeCalculatorForWeight.calculateReps(
            10,
            RpeCalculatorForWeight.calculatePercentageOfOneRepMax(
              workoutExercise.rpe.value,
              workoutExercise.reps,
            ),
          ),
        ),
      }
    } else if (workoutExercise.time_seconds) {
      ability = {
        type: AverageAbilityType.REPS_OR_TIME,
        ability: Math.round(
          RpeCalculatorForRepsOrTime.calculateTimeFromRpe(
            workoutExercise.rpe.value,
            workoutExercise.time_seconds,
          ),
        ),
      }
    }

    if (ability) {
      cache.value.set(workoutExercise.exercise.id, ability)
    }
  }

  return { getExerciseAbility, setExerciseAbility }
})

export default useAverageAbilityStore
