<template>
  <div class="client-auto-workout">
    <div v-if="!inProgress">
      <div style="display: flex; gap: 8px">
        <button
          v-for="feeling in latestFeelingsBeforeWorkout"
          :key="feeling.id"
          class="btn-brand btn-brand-white btn-brand-white-small"
          style="
            max-width: 120px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin-bottom: 8px;
          "
          @click="userFeelingBeforeWorkout = feeling.text"
        >
          {{ feeling.text }}
        </button>
      </div>
      <textarea
        v-model="userFeelingBeforeWorkout"
        :placeholder="placeholderText"
        rows="10"
      ></textarea>
      <button class="btn-brand btn-brand-green" @click="handleSubmit">
        Izveidot treninu!
      </button>
    </div>
    <div v-else-if="inProgress" style="background: white; padding: 16px">
      <h4 style="color: black">Tavs treniņš jau veidojas! Neaizver šo lapu!</h4>
      <div style="text-align: center">
        <div class="loader" style="margin: auto"></div>
      </div>
      <TimeProgressBar
        ref="timer"
        :time-seconds="averageWorkoutCreationTimeSeconds"
      />
    </div>
    <div v-else-if="error" style="background: white; padding: 16px">
      <h4 style="color: red">Kaut kas nogāja greizi!</h4>
    </div>
  </div>

  <Modal
    v-if="showEquipmentCtaModal"
    title="Lūdzu, norādi sev pieejamo aprīkojumu"
    @close="showEquipmentCtaModal = false"
  >
    <div>
      <p>
        Lai treniņi būtu maksimāli pielāgoti Taviem apstākļiem, lūdzu, norādi
        sev pieejamo aprīkojumu!
      </p>
      <a
        class="btn-brand btn-brand-green"
        href="/client-new/equipment"
        style="display: block; margin: 12px 0"
      >
        Noradit inventaru
      </a>
      <button
        class="btn-brand btn-brand-white"
        style="display: block; width: 100%"
        @click="showEquipmentCtaModal = false"
      >
        Varbut velak
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue'
import {
  parseChatgptWorkoutText,
  WorkoutMainData,
  ChatgptWorkoutCreator,
} from '@common/helpers/chatgpt'
import {
  ChatgptExerciseItem,
  ChatgptWorkoutDescriptionItem,
  ChatgptWorkoutItem,
} from '@common/repositories/ExerciseRepository'
import usePauseStore from 'trainer/src/stores/usePauseStore'
import ExerciseVM from 'trainer/src/models/ExerciseVM'
import useChatgptWorkoutStatistics from 'common/chatgpt/useChatgptWorkoutStatistics'
import WorkoutExerciseVM, {
  workoutExerciseForApi,
} from 'trainer/src/models/WorkoutExerciseVM'
import { resistanceBandOptions, rpeOptions } from 'trainer/src/helpers/Global'
import TimeProgressBar from '../components/TimeProgressBar.vue'
import WorkoutRepository from '@common/repositories/WorkoutRepository'
import { UserFeelingBeforeWorkout } from '@trainer/types/UserFeelingBeforeWorkout'
import ClientSettingsRepository from '@common/repositories/ClientSettingsRepository'
import Modal from 'common/components/Modal.vue'
import { useQuery } from '@tanstack/vue-query'

const props = defineProps<{
  promptId: number
  userId: number
  hasAnyWorkouts: boolean
  latestFeelingsBeforeWorkout: UserFeelingBeforeWorkout[]
}>()

const pauseStore = usePauseStore()

const chatgptWorkoutData = ref<ChatgptWorkoutItem[] | null>(null)

const workoutDescription = computed(() => {
  const descriptionItem = chatgptWorkoutData.value?.find(
    (x) => 'description' in x && x.description,
  ) as ChatgptWorkoutDescriptionItem | undefined
  return descriptionItem ? descriptionItem.description : ''
})

const { exercisesToBeAdded } = useChatgptWorkoutStatistics(chatgptWorkoutData)

const inProgress = ref(false)
const error = ref(false)
const apiErrorMessage = ref<string | null>(null)

const userFeelingBeforeWorkout = ref('')

const placeholderText = computed(
  () =>
    'Čau! ' +
    (props.hasAnyWorkouts
      ? 'Kādas sajūtas pēc iepriekšējā treniņa? Vai ir kādas sāpes, diskomforts? Ja nu ir kas cits, kas man kā trenerim būtu jāzina, uzraksti, ņemšu vērā!'
      : 'Kā šodien jūties?'),
)

const createExerciseForApi = async (
  exercise: ExerciseVM,
  initialData: WorkoutMainData,
) => {
  const newExercise = new WorkoutExerciseVM(exercise, null)

  if (initialData.weight) newExercise.weight = initialData.weight
  if (initialData.reps) newExercise.reps = initialData.reps
  if (initialData.rpe) {
    newExercise.rpe =
      rpeOptions.find((x) => x.value === initialData.rpe) ?? null
  }
  if (initialData.time_seconds) {
    newExercise.time_seconds = initialData.time_seconds
  }
  if (initialData.bandResistance) {
    newExercise.resistance_bands =
      resistanceBandOptions.find(
        (x) => x.value === initialData.bandResistance,
      ) ?? null
  }
  if (initialData.paceMinKm) {
    newExercise.pace_min_km = initialData.paceMinKm
  }
  if (initialData.speedKmH) {
    newExercise.speed_km_h = initialData.speedKmH
  }

  return newExercise
}

const createWorkout = async () => {
  const exercises: WorkoutExerciseVM[] = []
  for (const x of exercisesToBeAdded.value) {
    if (!x.options.length) continue
    const toAdd = x.options[0]
    exercises.push(
      await createExerciseForApi(toAdd.exercise, toAdd.initialData),
    )
  }

  await WorkoutRepository.create({
    student_id: props.userId,
    description: workoutDescription.value,
    workoutExercises: exercises.map(workoutExerciseForApi),
  })
}

const timer = ref<{ startProgress: () => void } | null>(null)

const { data: clientSettings } = useQuery({
  queryKey: ['client-settings'],
  queryFn: () => ClientSettingsRepository.getByUser(props.userId),
})

const showEquipmentCtaModal = ref(false)
const equipmentCtaModalShown = ref(false)

const handleSubmit = async () => {
  if (
    !equipmentCtaModalShown.value &&
    clientSettings.value &&
    !clientSettings.value.should_use_equipment_set
  ) {
    showEquipmentCtaModal.value = true
    equipmentCtaModalShown.value = true
    return
  }

  inProgress.value = true

  await nextTick()
  timer.value?.startProgress()

  const chatgptWorkoutCreator = new ChatgptWorkoutCreator(props.userId)
  let promptText: string | null = null
  try {
    promptText = await chatgptWorkoutCreator.generatePromptText({
      defaultPromptId: props.promptId,
      userFeelingBeforeWorkout: userFeelingBeforeWorkout.value,
    })
  } catch (e) {
    console.error(e)
  }

  if (!promptText) return

  apiErrorMessage.value = null
  const [text, errorMessage] =
    await chatgptWorkoutCreator.createChatgptWorkoutText(promptText)

  if (text) {
    chatgptWorkoutData.value = parseChatgptWorkoutText(text)
    if (!chatgptWorkoutData.value) return

    await pauseStore.awaitPauseLoaded()
    const exercisesList = await chatgptWorkoutCreator.getExercisesList(
      chatgptWorkoutData.value,
    )
    if (!exercisesList) return

    chatgptWorkoutData.value.forEach((x) => {
      if (!('notExercise' in x)) x.modelsLoaded = true
    })
    await Promise.all(
      (
        chatgptWorkoutData.value.filter(
          (x) => !('notExercise' in x),
        ) as ChatgptExerciseItem[]
      ).map((x) =>
        chatgptWorkoutCreator.mapChatgptWorkoutItem(
          x,
          exercisesList,
          // can be cast safely due to pause being awaited within this function (await pauseStore.awaitPauseLoaded())
          pauseStore.pause as ExerciseVM,
        ),
      ),
    )
  } else if (errorMessage) {
    apiErrorMessage.value = errorMessage
  }

  try {
    await createWorkout()
    window.location.reload()
  } catch (e) {
    console.error(e)
    error.value = true
  } finally {
    inProgress.value = false
  }
}

const averageWorkoutCreationTimeSeconds = 90
const maxWorkoutCreationTimeSeconds = 240

const backupWorkoutAssigningTimeout = ref<ReturnType<typeof setTimeout>>()
watch(inProgress, (n) => {
  if (!n || backupWorkoutAssigningTimeout.value) return

  backupWorkoutAssigningTimeout.value = setTimeout(async () => {
    try {
      await WorkoutRepository.assignBackupWorkout(props.userId)
      window.location.reload()
      inProgress.value = false
    } catch (e) {
      console.error(e)
      error.value = true
    }
  }, maxWorkoutCreationTimeSeconds * 1000)
})
</script>

<style lang="scss" scoped>
.client-auto-workout {
  text-align: center;
}

textarea {
  margin-bottom: 16px;
  width: 100%;
}
</style>
