<template>
  <div class="container2">
    <div class="flex-container">
      <div class="flex-item">
        <label for="">Treniņa teksts no ChatGPT</label>
        <textarea
          v-model="chatgptWorkoutText"
          class="full-width"
          rows="20"
        ></textarea>
      </div>
      <div class="flex-item right-side-container">
        <div>
          <p>
            Tiek attēloti tikai tie vingrojumi no progresijas ķēdēm, kuriem pie
            vajadzīgā RPE sanāk derīgs reižu daudzums (5 - 18 reizes)
          </p>
          <button
            class="btn btn-primary"
            :disabled="
              !variousPrompts ||
              (loadingChatgptExercisesCount &&
                loadingChatgptExercisesCount.length > 0)
            "
            @click="parseChatgptWorkout"
          >
            Parsēt
          </button>
          <div v-if="chatgptDataStatistics && chatgptWorkoutData">
            <p>
              Kopā:
              <strong>{{ chatgptDataStatistics.inTotal }}</strong>
            </p>
            <p class="text-secondary">
              Ielādējas:
              <strong>{{ chatgptDataStatistics.loading }}</strong>
            </p>
            <p class="text-success">
              Atrasts 1 vingrojums:
              <strong>{{ chatgptDataStatistics.withOneExercise }}</strong>
            </p>
            <p class="text-warning">
              Atrasti vairāki vingrojumi:
              <strong>{{ chatgptDataStatistics.withManyExercises }}</strong>
            </p>
            <p class="text-danger">
              Netika atrasts neviens vingrojums:
              <strong>{{ chatgptDataStatistics.withNoExercises }}</strong>
            </p>
          </div>
        </div>
        <div class="to-add-container">
          <div v-if="!chatgptWorkoutLoading && exercisesToBeAdded?.length">
            <h3>Vingrojumi, kurus pievienot:</h3>
            <button class="btn btn-success" @click="addAllChatgptExercises">
              Pievienot visus
            </button>
            <p v-if="showExercisesAddedMessage" class="text-success">
              Vingrojumi pievienoti treniņam!
            </p>
            <ul>
              <li
                v-for="(exerciseToAdd, i) in exercisesToBeAdded"
                :key="i"
                :style="{ marginBottom: exerciseToAdd.isLast ? '32px' : '0' }"
              >
                <template v-if="exerciseToAdd.options?.length">
                  <p v-for="(option, j) in exerciseToAdd.options" :key="j">
                    <input
                      v-model="exerciseToAdd.pickedIndex.value"
                      :disabled="exerciseToAdd.options.length === 1"
                      type="radio"
                      :value="j"
                    />
                    <span>
                      <strong>{{ option.exercise.name }} </strong>:
                    </span>
                    <span v-if="option.initialData.weight">
                      Svars:
                      <strong>{{ option.initialData.weight }}</strong> kg;
                    </span>
                    <span v-if="option.initialData.reps">
                      Reizes: <strong>{{ option.initialData.reps }} </strong>;
                    </span>
                    <span v-if="option.initialData.time_seconds">
                      Laiks:
                      <strong>{{ option.initialData.time_seconds }} s</strong>;
                    </span>
                    <span v-if="option.initialData.rpe">
                      RPE: <strong>{{ option.initialData.rpe }} </strong>;
                    </span>
                    <span v-if="option.initialData.bandResistance">
                      Gumiju pretestība:
                      <strong>
                        {{
                          resistanceBandOptions.find(
                            (x) =>
                              x?.value === option.initialData.bandResistance,
                          )?.label
                        }} </strong
                      >;
                    </span>
                    <span v-if="option.initialData.paceMinKm">
                      Temps:
                      <strong>{{ option.initialData.paceMinKm }}</strong>
                      (min/km);
                    </span>
                    <span v-if="option.initialData.speedKmH">
                      Ātrums:
                      <strong>{{ option.initialData.speedKmH }}</strong>
                      (km/h);
                    </span>
                    <span v-if="option.initialData.distanceMeters">
                      Distance:
                      <strong>{{ option.initialData.distanceMeters }}</strong>
                      (m);
                    </span>
                  </p>
                </template>
                <span v-else><em>(Netika atrasts neviens vingrojums)</em></span>
              </li>
            </ul>
          </div>
          <p v-else-if="chatgptWorkoutLoading" class="loader"></p>
        </div>
      </div>
    </div>
    <div v-if="groupedChatgptWorkoutData" class="statistics">
      <ul class="boxes-container">
        <li
          v-for="(group, i) in groupedChatgptWorkoutData"
          :key="i"
          class="boxes-inner-container"
        >
          <div
            v-for="(chatgptExerciseItem, j) in group"
            :key="j"
            class="box-with-shadow"
          >
            <template v-if="!('notExercise' in chatgptExerciseItem)">
              <p>
                <span>
                  Nosaukums:
                  <strong>{{ chatgptExerciseItem.name }} </strong>;
                </span>
                <span v-if="chatgptExerciseItem.weight">
                  Svars:
                  <strong>{{ chatgptExerciseItem.weight }}</strong> kg;
                </span>
                <span v-if="chatgptExerciseItem.reps">
                  Reizes: <strong>{{ chatgptExerciseItem.reps }} </strong>;
                </span>
                <span v-if="chatgptExerciseItem.time_seconds">
                  Laiks:
                  <strong>{{ chatgptExerciseItem.time_seconds }} </strong>;
                </span>
                <span v-if="chatgptExerciseItem.rpe">
                  RPE: <strong>{{ chatgptExerciseItem.rpe }} </strong>;
                </span>
                <span v-if="chatgptExerciseItem.bandResistance">
                  Gumiju pretestība:
                  <strong>
                    {{
                      resistanceBandOptions.find(
                        (x) =>
                          x.value ===
                          (chatgptExerciseItem as ChatgptExerciseItem)
                            .bandResistance,
                      )?.label
                    }} </strong
                  >;
                </span>
                <span v-if="chatgptExerciseItem.paceMinKm">
                  Temps:
                  <strong>{{ chatgptExerciseItem.paceMinKm }}</strong>
                  (min/km);
                </span>
                <span v-if="chatgptExerciseItem.speedKmH">
                  Ātrums:
                  <strong>{{ chatgptExerciseItem.speedKmH }}</strong>
                  (km/h);
                </span>
                <span v-if="chatgptExerciseItem.distanceMeters">
                  Distance:
                  <strong>{{ chatgptExerciseItem.distanceMeters }}</strong>
                  (m);
                </span>
              </p>
              <div v-if="chatgptExerciseItem.is_pause && pauseStore.pause">
                <div class="list-group-item">
                  <p class="pause-item">
                    <span>
                      <a
                        :href="'/exercises/view?id=' + pauseStore.pause.id"
                        target="_blank"
                        title="Apskatīt pauzi"
                      >
                        <span class="glyphicon glyphicon-eye-open"></span>
                      </a>
                      <span class="ml-8">{{ pauseStore.pause.name }}</span>
                    </span>
                    <button
                      class="btn btn-primary btn-sm"
                      @click="
                        addExerciseWithInitialData(pauseStore.pause, {
                          name: pauseStore.pause.name,
                          time_seconds: chatgptExerciseItem.time_seconds,
                        })
                      "
                    >
                      <span
                        class="glyphicon glyphicon-plus"
                        title="Pievienot treniņam"
                      ></span>
                    </button>
                  </p>
                </div>
              </div>
              <div v-else>
                Vingrojumi platformā, kas iekļauj šo nosaukumu:
                <div v-if="!chatgptExerciseItem.modelsLoaded">
                  <p class="loader"></p>
                  <span class="text-info">Ielādē vingrojumus...</span>
                </div>
                <div v-if="chatgptExerciseItem.findingMostSimilarExercise">
                  <p class="loader"></p>
                  <p class="text-info">
                    Netika atrasts neviens vingrojums, pēc tagiem meklē
                    līdzīgāko no platformas...
                  </p>
                </div>
                <ul v-else-if="chatgptExerciseItem.models?.length">
                  <ChatgptExerciseWidget
                    v-for="model2 in chatgptExerciseItem.models"
                    :key="model2.exercise.id"
                    :chatgpt-exercise-item="chatgptExerciseItem"
                    :model="model2"
                    @click:add-to-workout="
                      (e, exercise, reps, weight) =>
                        handleAddToWorkoutClick(
                          e,
                          exercise,
                          chatgptExerciseItem,
                          reps,
                          weight,
                        )
                    "
                  />
                </ul>

                <div
                  v-if="
                    chatgptExerciseItem.errorWhileFindingMostSimilarExercise
                  "
                >
                  <p class="text-danger">
                    Notika kļūda meklējot līdzīgāko vingrojumu no platformas:
                    {{
                      chatgptExerciseItem.errorWhileFindingMostSimilarExercise
                    }}
                  </p>
                </div>
                <div v-if="chatgptExerciseItem.mostSimilarExerciseChoice">
                  <div class="text-success">
                    Tika atrasts līdzīgākais vingrojums no platformas, un
                    chatgpt api domā, ka tas ir:
                    <div
                      v-if="
                        chatgptExerciseItem.mostSimilarExerciseChoice === 'A'
                      "
                      class="text-info"
                    >
                      Alternatīvs meklētā vingrojuma nosaukums (tas automātiski
                      tika pielikts)
                    </div>
                    <div v-else class="text-info">Pavisam cits vingrojums</div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="'sectionTitle' in chatgptExerciseItem">
              <div>
                <h3>{{ chatgptExerciseItem.sectionTitle }}</h3>
                <p>
                  {{ chatgptExerciseItem.rounds }}
                  {{ chatgptExerciseItem.rounds === 1 ? 'round' : 'rounds' }}
                </p>
              </div>
            </template>
          </div>
        </li>
        <br />
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { resistanceBandOptions, rpeOptions } from '../helpers/Global'
import { nextTick, ref, watch } from 'vue'
import ExerciseVM from '../models/ExerciseVM'
import {
  parseChatgptWorkoutText,
  WorkoutMainData,
  ChatgptWorkoutCreator,
} from '@common/helpers/chatgpt'
import {
  ChatgptExerciseItem,
  ChatgptWorkoutDescriptionItem,
  ChatgptWorkoutItem,
} from '@common/repositories/ExerciseRepository'
import WorkoutExerciseVM from '../models/WorkoutExerciseVM'
import averageAbilityCacheStore from '../stores/useAverageAbilityStore'
import ChatgptExerciseWidget from '../components/ChatgptExerciseWidget.vue'
import VariousPromptsRepository, {
  VariousPrompts,
} from '@common/repositories/VariousPromptsRepository'
import { isEqual, isObject, transform } from 'lodash'
import usePauseStore from '../stores/usePauseStore'
import useChatgptWorkoutStatistics from 'common/chatgpt/useChatgptWorkoutStatistics'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    userId: number
    chatgptWorkoutTextProp?: string
  }>(),
  { chatgptWorkoutTextProp: '' },
)

const emit = defineEmits<{
  'add-workout-exercise': [workoutExercise: WorkoutExerciseVM]
  'update:workout-description': [description: string]
}>()

const { getExerciseAbility } = averageAbilityCacheStore()
const pauseStore = usePauseStore()

if (props.chatgptWorkoutTextProp) {
  nextTick(() => {
    document.getElementById('chatgpt-parsing-tab')?.click()
  })
}

const chatgptWorkoutText = ref(props.chatgptWorkoutTextProp)
const chatgptWorkoutData = ref<ChatgptWorkoutItem[] | null>(null)

const workoutDescription = computed(() => {
  const descriptionItem = chatgptWorkoutData.value?.find(
    (x) => 'description' in x && x.description,
  ) as ChatgptWorkoutDescriptionItem | undefined
  return descriptionItem ? descriptionItem.description : ''
})

watch(workoutDescription, (n) => {
  if (n) {
    emit('update:workout-description', n)
  }
})

const {
  exercisesToBeAdded,
  groupedChatgptWorkoutData,
  chatgptDataStatistics,
  loadingChatgptExercisesCount,
  chatgptWorkoutLoading,
} = useChatgptWorkoutStatistics(chatgptWorkoutData)

const showExercisesAddedMessage = ref(false)

const addAllChatgptExercises = async (e: Event) => {
  const target = e.target as HTMLButtonElement
  target.disabled = true
  setTimeout(() => (target.disabled = false), 1000)

  for (const x of exercisesToBeAdded.value) {
    if (!x.options.length) continue
    const toAdd = x.options[x.pickedIndex.value]
    await addExerciseWithInitialData(toAdd.exercise, toAdd.initialData)
  }

  showExercisesAddedMessage.value = true
  setTimeout(() => {
    showExercisesAddedMessage.value = false
  }, 3000)
}

const handleAddToWorkoutClick = (
  e: Event,
  exercise: ExerciseVM,
  chatGptExercise,
  reps: number | null = null,
  weight: number | null = null,
) => {
  const target = e.target as HTMLButtonElement
  target.disabled = true
  setTimeout(() => (target.disabled = false), 1000)
  const workoutMainData: WorkoutMainData = chatGptExercise
  if (reps) workoutMainData.reps = reps
  if (weight) workoutMainData.weight = weight
  addExerciseWithInitialData(exercise, chatGptExercise as WorkoutMainData)
}

const addExerciseWithInitialData = async (
  exercise: ExerciseVM,
  initialData: WorkoutMainData,
) => {
  const newExercise = new WorkoutExerciseVM(exercise, null)
  const exerciseAbility = await getExerciseAbility(exercise.id, props.userId)
  const allProvided = initialData.reps && initialData.weight && initialData.rpe
  if (initialData.weight && (!exerciseAbility || !allProvided)) {
    newExercise.weight = initialData.weight
  }
  if (initialData.reps) newExercise.reps = initialData.reps
  if (initialData.rpe) {
    newExercise.rpe =
      rpeOptions.find((x) => x.value === initialData.rpe) ?? null
  }
  if (initialData.time_seconds)
    newExercise.time_seconds = initialData.time_seconds
  if (initialData.bandResistance) {
    newExercise.resistance_bands =
      resistanceBandOptions.find(
        (x) => x.value === initialData.bandResistance,
      ) ?? null
  }
  if (initialData.paceMinKm) {
    newExercise.pace_min_km = initialData.paceMinKm
  }
  if (initialData.speedKmH) {
    newExercise.speed_km_h = initialData.speedKmH
  }

  emit('add-workout-exercise', newExercise)
}

const variousPrompts = ref<VariousPrompts>()
const loadPromptTemplates = async () => {
  variousPrompts.value = await VariousPromptsRepository.get()
}
loadPromptTemplates()

const deepDiffBetweenObjects = (
  object: Record<string, unknown>,
  base: Record<string, any>,
) => {
  const changes = (
    object: Record<string, unknown>,
    base: Record<string, any>,
  ) => {
    return transform(
      object,
      (result: Record<string, any>, value: any, key: string) => {
        if (!isEqual(value, base[key])) {
          if (isObject(value) && isObject(base[key])) {
            result[key] = changes(
              value as Record<string, unknown>,
              base[key as keyof typeof base],
            )
          } else {
            result[key] = value
          }
        }
      },
    )
  }
  return changes(object, base)
}

const getChangedIndexes = () => {
  const oldChatgptWorkoutData = chatgptWorkoutData.value
  let changedIndexes: number[] | null = null
  if (oldChatgptWorkoutData) {
    changedIndexes = []
    const unchagedIndexes: number[] = []

    const diff = deepDiffBetweenObjects(
      { ...chatgptWorkoutData.value },
      { ...oldChatgptWorkoutData },
    )
    Object.entries(diff).forEach(([key, value]) => {
      Object.getOwnPropertyNames(value).length > 0
        ? changedIndexes!.push(parseInt(key))
        : unchagedIndexes.push(parseInt(key))
    })

    unchagedIndexes.forEach((i) => {
      chatgptWorkoutData.value![i] = oldChatgptWorkoutData[i]
    })
  }
  return changedIndexes
}

const parseChatgptWorkout = async () => {
  if (!chatgptWorkoutText.value) return
  // must be called before setting chatgptWorkoutData.value!!!
  const changedIndexes = getChangedIndexes()
  chatgptWorkoutData.value = parseChatgptWorkoutText(chatgptWorkoutText.value)
  if (!chatgptWorkoutData.value) return

  await pauseStore.awaitPauseLoaded()
  const chatgptWorkoutCreator = new ChatgptWorkoutCreator(props.userId)

  const exercisesList = await chatgptWorkoutCreator.getExercisesList(
    chatgptWorkoutData.value,
    changedIndexes,
  )
  if (!exercisesList) return

  chatgptWorkoutData.value.forEach((x) => {
    if (!('notExercise' in x)) x.modelsLoaded = true
  })
  await Promise.all(
    chatgptWorkoutData.value
      .filter(
        (x, i) =>
          !('notExercise' in x) &&
          (!changedIndexes || changedIndexes.includes(i)),
      )
      .map((x) =>
        chatgptWorkoutCreator.mapChatgptWorkoutItem(
          x as ChatgptExerciseItem,
          exercisesList,
          // can be cast safely due to pause being awaited within this function (await pauseStore.awaitPauseLoaded())
          pauseStore.pause as ExerciseVM,
        ),
      ),
  )
}
</script>

<style scoped>
.container2 {
  padding: 32px;
}

.flex-container {
  display: flex;
  gap: 16px;
}

.flex-item {
  flex: 1;
}

.full-width {
  width: 100%;
}

.statistics {
  margin-top: 32px;
}

.right-side-container {
  display: flex;
  gap: 16px;
}

.to-add-container {
  flex: 0 0 500px;
}

.boxes-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.boxes-inner-container {
  list-style-type: none;
  flex-basis: 400px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.box-with-shadow {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
  list-style-type: none;
}
</style>
