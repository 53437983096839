import { createApp } from 'vue'
import jquery from 'jquery'
import 'vue-select/dist/vue-select.css'
import './style/app.scss'
import { createPinia } from 'pinia'
import WorkoutCreation from './apps/WorkoutCreation.vue'
import CreatePromptForClient from './apps/CreatePromptForClient.vue'

declare global {
  interface Window {
    $: typeof jquery
    setupInterchangeableExerciseSelects: () => void
  }
}

const workoutCreationId = 'workout-creation'
const templateCreationId = 'template-creation'
if (document.getElementById(workoutCreationId)) {
  const searchParams = new URLSearchParams(window.location.search)
  createApp(WorkoutCreation, {
    userId: parseInt(searchParams.get('userId') as string),
    chatgptWorkoutText: searchParams.get('chatgptWorkoutText'),
  })
    .use(createPinia())
    .mount(`#${workoutCreationId}`)
} else if (document.getElementById(templateCreationId)) {
  // new TemplateCreation().$mount('#' + templateCreationId)
}

const createPromptForClientClass = 'create-prompt-for-client'
const createPromptForClientEls = document.querySelectorAll(
  `.${createPromptForClientClass}`,
)
Array.from(createPromptForClientEls).forEach((el) => {
  createApp(CreatePromptForClient, {
    promptId: parseInt((el as HTMLElement).dataset.promptId!),
  })
    .use(createPinia())
    .mount(el)
})
