import Repository from '@common/repositories/Repository'
import axios from 'axios'

export type AuditForm = {
  category: string
  sub_category: string
  message?: string
  data?: Record<string, any>
}

export default class AuditRepository extends Repository {
  static get baseUrl() {
    return '/audit'
  }

  static async create(data: AuditForm) {
    return await axios.post(`${this.baseUrl}/api-create`, data)
  }
}
