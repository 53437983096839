import RepMaxCalculator from './RepMaxCalculator'
import RpeCalculatorForWeight from './RpeCalculatorForWeight'

export default class RepMaxCalculatorEpley extends RepMaxCalculator {
  static calculateOneRepMax(weight: number, reps: number): number {
    return weight * (1 + reps / 30)
  }

  static calculatePercentageOfOneRepMax(rpe: number, reps: number): number {
    return 1 / (1 + (rpe * (reps / RpeCalculatorForWeight.MAX_RPE)) / 30)
  }

  static calculateReps(rpe: number, percOf1RM: number): number {
    return rpe * (1 / percOf1RM - 1) * 30
  }

  static calculateRpe(reps: number, percOf1RM: number): number {
    return reps / ((1 / percOf1RM - 1) * 30)
  }

  static calculateMaxReps(rpe: number, reps: number): number {
    return rpe * (reps / RpeCalculatorForWeight.MAX_RPE)
  }
}
