<template>
  <teleport to="body">
    <div
      v-bind="$attrs"
      ref="rootEl"
      class="fade modal"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button class="close" type="button" @click="emit('close')">
              <span>&times;</span>
            </button>
            <h3 v-if="title" id="modal-title" class="modal-title">
              {{ title }}
            </h3>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue'

const { title } = defineProps<{ title?: string }>()

const emit = defineEmits<{ (e: 'close'): void }>()

const rootEl = ref()

onMounted(() => {
  //@ts-ignore
  window.$(rootEl.value).modal('show')
})

onBeforeUnmount(() => {
  //@ts-ignore
  window.$(rootEl.value).modal('hide')
})
</script>
