import Repository from '@common/repositories/Repository'
import axios from 'axios'
import { Workout } from 'trainer/src/types/Workout'

export default class WorkoutRepository extends Repository {
  static get baseUrl() {
    return '/workout'
  }

  static async ofUser(userId: number) {
    const res = await axios.get(`${this.baseUrl}/api-of-student`, {
      params: { id: userId },
    })
    if (res?.data) return res.data as Workout[]
  }

  static async create(workout: Partial<Workout>) {
    await axios.post(`${this.baseUrl}/api-create`, workout)
  }

  static async saveAsDraft(workout: Partial<Workout>) {
    await axios.post(`${this.baseUrl}/api-save-as-draft`, workout)
  }

  static async publishDraft(workoutId: number) {
    await axios.post(
      `${this.baseUrl}/api-publish-draft`,
      {},
      { params: { workoutId } },
    )
  }

  static async assignBackupWorkout(studentId: number) {
    await axios.post(
      `${this.baseUrl}/api-assign-backup-workout`,
      {},
      { params: { studentId } },
    )
  }

  static async delete(id: number) {
    await axios.delete(`${this.baseUrl}/api-delete`, { params: { id } })
  }
}
