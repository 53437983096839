import { ExerciseVideo } from '../types/ExerciseVideo'
import { Exercise } from '../types/Exercise'

export default class ExerciseVM implements Exercise {
  id = 0
  author_id = 0
  name = ''
  description = null
  is_pause = false
  needs_evaluation = false
  is_archived = false
  is_bodyweight = false
  is_ready = false
  has_reps = false
  has_weight = false
  has_time = false
  has_resistance_bands = false
  has_mode = false
  has_incline_percent = false
  has_pace = false
  has_speed = false
  has_distance = false
  has_pulse = false
  has_height = false
  weight_is_for_each_side = false
  time_is_for_each_side = false
  video = null
  equipment_video = null
  equipment_video_thumbnail = null
  technique_video = null
  popularity_type = ''
  weight_type = ''
  single_rep_description = null
  created_at = ''
  updated_at = ''

  videos: ExerciseVideo[] = []

  constructor(parsedExercise) {
    Object.assign(this, parsedExercise)
  }

  static createFrom(exerciseFromApi) {
    const copy = { ...exerciseFromApi }
    copy.id = parseInt(copy.id)
    copy.author_id = parseInt(copy.author_id)

    return new ExerciseVM(copy)
  }
}
