import Repository from '@common/repositories/Repository'
import axios from 'axios'

export default class OpenaiApiFoundExerciseRepository extends Repository {
  static get baseUrl() {
    return '/openai-api-found-exercise'
  }

  static async addAlternateExerciseName(data: {
    given_exercise_name: string
    given_exercise_attributes: string
    found_exercise_id: number
    request: string
    response: string
  }) {
    return (await axios.post(`${this.baseUrl}/api-create`, data)).data
  }
}
