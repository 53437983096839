import RepMaxCalculator from './RepMaxCalculator'
import RpeCalculatorForWeight from './RpeCalculatorForWeight'

export default class RepMaxCalculatorBrzycki extends RepMaxCalculator {
  private static readonly CALC_BASE = 0.98174
  private static readonly CALC_SLOPE = 17

  static calculateOneRepMax(weight: number, reps: number) {
    return weight / (1.0278 - 0.0278 * reps)
  }
  public static calculatePercentageOfOneRepMax(rpe: number, reps: number) {
    return Math.pow(this.CALC_BASE, 2 * (reps - rpe) + this.CALC_SLOPE)
  }
  public static calculateReps(rpe: number, percOf1RM: number) {
    return (
      Math.log(percOf1RM) / (2 * Math.log(this.CALC_BASE)) +
      rpe -
      this.CALC_SLOPE / 2
    )
  }
  public static calculateRpe(reps: number, percOf1RM: number) {
    return (
      reps -
      (Math.log(percOf1RM) / Math.log(this.CALC_BASE) - this.CALC_SLOPE) / 2
    )
  }
  public static calculateMaxReps(rpe: number, reps: number) {
    return reps - rpe + RpeCalculatorForWeight.MAX_RPE
  }
}
