import { AxiosError } from 'axios'

export type Rpe =
  | 0.5
  | 1
  | 1.5
  | 2
  | 2.5
  | 3
  | 3.5
  | 4
  | 4.5
  | 5
  | 5.5
  | 6
  | 6.5
  | 7
  | 7.5
  | 8
  | 8.5
  | 9
  | 9.5
  | 10

export const MAX_RPE = 10

export function guidGenerator() {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  )
}

export const rpes: Rpe[] = [
  10, 9.5, 9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5, 5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1,
  0.5,
]

export const rpeOptions = rpes.map((rpe) => ({ value: rpe, label: rpe }))

export const pauseLengths = [
  15, 30, 45, 60, 90, 120, 150, 180, 240, 300, 360,
] as const

export const evalValueToText = {
  2: 'Garlaicīgi',
  4: 'Viegli',
  6: 'Nedaudz grūti',
  8: 'Ļoti grūti',
  10: 'Neiespējami',
}

export const formatAbilityRange = (abilityRange, unit) => {
  const min = abilityRange.min
  const max = abilityRange.max
  const suffix = ' (' + unit + ')'

  const main = min === max ? min : [min, max].join('-')

  return main + suffix
}

export const getCsrfToken = (): string | null => {
  const csrfElement = document.querySelector(
    'meta[name=csrf-token]',
  ) as HTMLMetaElement | null
  if (!csrfElement) {
    console.warn('No csrf element on page, falling back to window object')
    //@ts-ignore
    return window.clientSpa?.csrfToken ?? null
  }
  return csrfElement.content
}

export const resistanceBandOptions = [
  { value: 1, label: 'Maza' },
  { value: 2, label: 'Vidēja' },
  { value: 3, label: 'Liela' },
  { value: 4, label: 'Maza+liela' },
  { value: 5, label: 'Vidēja+liela' },
  { value: 6, label: 'Maza+vidēja+liela' },
]

export const getApiErrorMessage = (e: AxiosError<unknown, any> | null) => {
  let message = 'kaut kas nogāja greizi'
  if (e?.response?.data) {
    const responseData = e.response.data as Record<string, any>
    if (
      'error' in responseData &&
      typeof responseData.error === 'object' &&
      'message' in responseData.error &&
      typeof responseData.error.message === 'string'
    ) {
      message = responseData.error.message
    }
  }

  return message
}
