import WorkoutExerciseDisplayVM from 'trainer/src/models/WorkoutExerciseDisplayVM'
import { Workout } from 'trainer/src/types/Workout'
import ExerciseRepository, {
  ChatgptExerciseItem,
  ChatgptWorkoutItem,
  ExerciseFromProgressionChain,
  SimilarNameResponse,
} from '@common/repositories/ExerciseRepository'
import {
  generateCompletion,
  getLatestChatModel,
} from '@common/repositories/OpenAIRepository'
import { getApiErrorMessage } from 'trainer/src/helpers/Global'
import WorkoutRepository from '@common/repositories/WorkoutRepository'
import WorkoutPromptRepository from '@common/repositories/WorkoutPromptRepository'
import ExerciseVM from 'trainer/src/models/ExerciseVM'
import OpenaiApiFoundExerciseRepository from '@common/repositories/OpenaiApiFoundExerciseRepository'
import VariousPromptsRepository from '@common/repositories/VariousPromptsRepository'
import { Model } from '@common/types/OpenAI'
import ClientDetailsRepository from '@common/repositories/ClientDetailsRepository'

export type WorkoutMainData = {
  id?: number
  name: string
  weight?: number
  reps?: number
  rpe?: number
  time_seconds?: number
  is_pause?: boolean
  bandResistance?: 1 | 2 | 3 | null
  paceMinKm?: number
  speedKmH?: number
  distanceMeters?: number
}

export function parseChatgptWorkoutText(text: string) {
  let id = 1

  const setTitles = [
    'tri-set',
    'tri set',
    'triset',
    'super-set',
    'super set',
    'superset',
  ]

  const warmupCooldownTitles = [
    'warmup',
    'warm up',
    'warm-up',
    'cooldown',
    'cool down',
    'cool-down',
  ]

  const overviewPrefixes = ['overview:', 'pārskats:']

  const res: ChatgptWorkoutItem[] = []

  text.split('\n').forEach((line) => {
    const trimmedLowercaseLine = line.trim().toLowerCase()

    const matchingOverviewPrefix = overviewPrefixes.find((prefix) =>
      trimmedLowercaseLine.startsWith(prefix),
    )

    if (matchingOverviewPrefix) {
      const descriptionText = line
        .substring(matchingOverviewPrefix.length)
        .trim()

      res.push({
        id,
        description: descriptionText,
        notExercise: true,
      })
      return
    }

    const sectionTitle =
      setTitles.find((x) => trimmedLowercaseLine.includes(x)) ??
      warmupCooldownTitles.find((x) => trimmedLowercaseLine.includes(x))
    if (sectionTitle) {
      const roundsMatch = line.match(/\b(\d+)\s*rounds\b/i)
      res.push({
        id,
        sectionTitle: sectionTitle ?? '(Nevarēja izparsēt sadaļas nosaukumu)',
        rounds: roundsMatch?.[1] ? parseInt(roundsMatch[1]) : 1,
        notExercise: true,
      })
      return
    }

    const nameMatch = line.match(/^(?:\d+\.\s)?([^:]+)(?=:)/)

    if (!nameMatch) return

    const weightMatch = line.match(/(\d+(\.\d+)?)\s*kg/)
    const repsMatch = line.match(/(\d+)\s+(reps|steps)/)
    const rpeMatch = line.match(/\b(\d+(.\d+)?)\sRPE|\sRPE\s*(\d+(.\d+)?)/i)
    const timeMatch = line.match(/(\d+)\s*(sec|min)/i)
    const isPauseMatch = line.match(/\bpause|rest|break\b/i)
    const bandResistanceMatch = line.match(
      /\b(?:light|medium|heavy|hard)[ \t]*resistance\b|,[ \t]*(?:light|medium|heavy|hard)\b/i,
    )
    const distanceMatch = line.match(/(\d+(?:\.\d+)?)\s*m(?!\w)/i)
    const speedMatch = line.match(/(\d+(\.\d+)?)\s*km\/h/i)
    const paceMatch = line.match(/(\d+(\.\d+)?)\s*min\/km/i)

    const result: ChatgptExerciseItem = { id, name: nameMatch[1].trim() }
    id++

    if (weightMatch) result.weight = parseFloat(weightMatch[1])

    if (repsMatch) result.reps = parseFloat(repsMatch[1])

    if (rpeMatch && rpeMatch.length === 5) {
      result.rpe = rpeMatch.map((x) => parseFloat(x)).find((x) => !isNaN(x))
    }

    if (timeMatch) {
      let time = parseFloat(timeMatch[1])
      if (timeMatch[2] === 'min') time *= 60
      result.time_seconds = time
    }

    if (isPauseMatch) result.is_pause = true

    if (bandResistanceMatch) {
      const resistance = bandResistanceMatch[0].startsWith(',')
        ? bandResistanceMatch[0].substring(1).trim() // Remove the leading comma if present
        : bandResistanceMatch[0].trim()
      result.bandResistance = ((resistance: string) => {
        switch (resistance) {
          case 'light':
          case 'light resistance':
            return 1
          case 'medium':
          case 'medium resistance':
            return 2
          case 'heavy':
          case 'heavy resistance':
          case 'hard':
          case 'hard resistance':
            return 3
          default:
            return null
        }
      })(resistance.toLowerCase().trim())
    }

    if (distanceMatch) {
      let distance = parseFloat(distanceMatch[1])
      if (distanceMatch[3] === 'km') distance *= 1000
      result.distanceMeters = distance
    }

    if (speedMatch) result.speedKmH = parseFloat(speedMatch[1])

    if (paceMatch) result.paceMinKm = parseFloat(paceMatch[1])

    res.push(result)
  })

  return res
}

export function formatUserWorkoutsForChatgpt(userWorkouts: Workout[]) {
  const textRows: string[] = []
  const lastWorkouts = userWorkouts.slice(0, 3)
  lastWorkouts.forEach((workout) => {
    textRows.push(`Workout on ${workout.created_at}`)

    const seenExerciseNames = new Set()
    workout.workoutExercises
      .filter((we) => {
        if (
          !we.exercise.needs_evaluation ||
          !we.assigned_rpe ||
          we.assigned_rpe <= 5 ||
          we.is_skipped
        ) {
          return false
        }
        if (!seenExerciseNames.has(we.exercise.name)) {
          seenExerciseNames.add(we.exercise.name)
          return true
        }
        return false
      })
      .map((workoutExercise) => new WorkoutExerciseDisplayVM(workoutExercise))
      .forEach((workoutExercise) => {
        const exerciseName = workoutExercise.executedExerciseName
        const exerciseData: string[] = []
        if (workoutExercise.assigned_rpe) {
          exerciseData.push(`Assigned RPE: ${workoutExercise.assigned_rpe}`)
        }
        if (
          workoutExercise.assignedAndEvaluationRpeDifference &&
          workoutExercise.evaluation &&
          workoutExercise.evaluation.evaluation_rpe_range
        ) {
          exerciseData.push(
            `Evaluated as RPE ${workoutExercise.evaluation.evaluation_rpe_range
              .map((x) => Math.round(x))
              .join('-')}`,
          )
          exerciseData.push(`(${workoutExercise.rpeDifferenceText})`)
        }
        if (workoutExercise.reps) {
          exerciseData.push(`Reps: ${workoutExercise.reps}`)
        }
        if (workoutExercise.time_seconds) {
          exerciseData.push(`Time: ${workoutExercise.time_seconds}`)
        }
        if (workoutExercise.actualWeightText) {
          exerciseData.push(`Weight: ${workoutExercise.actualWeightText}`)
        }
        workoutExercise
          .getExtraAttributeTexts('en')
          .forEach((x) => exerciseData.push(x))

        textRows.push(`${exerciseName}: ${exerciseData.join('; ')}`)
      })
    textRows.push('</br>')
  })

  return textRows.join('</br>')
}

export class ChatgptWorkoutCreator {
  constructor(private userId: number) {}

  private async createExercisesPromptText(
    exerciseName: string,
    dbExerciseNamesText: string,
  ) {
    const promptText = await VariousPromptsRepository.createForUser({
      key: 'find_most_similar_exercise',
      userId: this.userId,
      variables: {
        exercise_name: exerciseName,
        database_exercises: dbExerciseNamesText,
      },
    })
    return promptText.result
  }

  async createChatgptWorkoutText(promptText: string) {
    const model = await getLatestChatModel('gpt-4')
    if (!model) return [null, 'Neizdevās atrast modeli!']

    let [gptWorkoutText, e] = await generateCompletion(model.id, [
      { role: 'system', content: promptText },
    ])

    while (e) {
      ;[gptWorkoutText, e] = await generateCompletion(model.id, [
        { role: 'system', content: promptText },
      ])
    }

    return [gptWorkoutText, null]
  }

  async setClientDefaultWorkoutPrompt() {
    const promptToGetDefaultPromptId =
      await VariousPromptsRepository.createForUser({
        key: 'choose_default_workout_prompt',
        userId: this.userId,
      })
    const model = await getModel()
    if (!model) return
    const [responseText, e] = await generateCompletion(model.id, [
      { role: 'user', content: promptToGetDefaultPromptId.result },
    ])
    if (!responseText) return
    const chosenPromptName = responseText.split('\n')[0].trim()
    const prompt = await WorkoutPromptRepository.getByName(chosenPromptName)
    if (prompt) {
      await ClientDetailsRepository.update(this.userId, {
        default_workout_prompt_id: prompt.id,
      })
    }

    return prompt
  }

  async getClientSpecificWorkoutPrompt({
    lastWorkoutsText,
    userFeelingBeforeWorkout,
  }: {
    lastWorkoutsText: string | null
    userFeelingBeforeWorkout: string
  }) {
    const promptText = await VariousPromptsRepository.createForUser({
      key: 'choose_specific_workout_prompt',
      userId: this.userId,
      variables: {
        last_workouts_text: lastWorkoutsText,
        user_feeling_before_workout: userFeelingBeforeWorkout,
      },
    })
    const model = await getModel()
    if (!model) return
    const [responseText, e] = await generateCompletion(model.id, [
      { role: 'user', content: promptText.result },
    ])
    if (!responseText) return
    const chosenPromptName = responseText.split('\n')[0].trim()
    return await WorkoutPromptRepository.getByName(chosenPromptName)
  }

  async generatePromptText(
    options:
      | { promptId: number }
      | { defaultPromptId: number; userFeelingBeforeWorkout: string },
  ) {
    const userWorkouts = await WorkoutRepository.ofUser(this.userId)
    const lastWorkoutsText = userWorkouts
      ? formatUserWorkoutsForChatgpt(userWorkouts)
      : null

    let promptId: number
    if ('promptId' in options) {
      promptId = options.promptId
    } else {
      try {
        const clientDetails = await ClientDetailsRepository.getByUser(
          this.userId,
        )
        if (!clientDetails.default_workout_prompt_id) {
          const userDefaultPrompt = await this.setClientDefaultWorkoutPrompt()
          promptId = userDefaultPrompt?.id ?? options.defaultPromptId
        } else {
          promptId = clientDetails.default_workout_prompt_id
        }

        if (options.userFeelingBeforeWorkout.length) {
          const prompt = await this.getClientSpecificWorkoutPrompt({
            lastWorkoutsText,
            userFeelingBeforeWorkout: options.userFeelingBeforeWorkout,
          })
          promptId = prompt?.id ?? options.defaultPromptId
        }
      } catch (e) {
        console.error(e)
        promptId = options.defaultPromptId
      }
    }

    return (
      await WorkoutPromptRepository.createForUser({
        id: promptId,
        userId: this.userId,
        lastWorkoutsText,
        userFeelingBeforeWorkout:
          'userFeelingBeforeWorkout' in options
            ? options.userFeelingBeforeWorkout
            : null,
      })
    ).result.replaceAll('\r\n', '<br />')
  }

  async getExercisesList(
    chatgptWorkoutData: ChatgptWorkoutItem[],
    changedIndexes: number[] | null = null,
  ) {
    const exercisePayload = (
      chatgptWorkoutData.filter(
        (x, i) =>
          !('notExercise' in x) &&
          !x.is_pause &&
          (!changedIndexes || changedIndexes.includes(i)),
      ) as ChatgptExerciseItem[]
    ).map((x) => ({
      userId: this.userId,
      id: x.id,
      name: x.name,
      rpe: x.rpe,
      weight: x.weight,
      hasWeight: !!x.weight,
      hasReps: !!x.reps,
      hasTime: !!x.time_seconds,
      hasResistanceBands: !!x.bandResistance,
    }))

    let exercisesList: SimilarNameResponse | null
    try {
      exercisesList =
        await ExerciseRepository.getOfSimilarNameAndAttributes(exercisePayload)
    } catch (e) {
      console.error(e)
      exercisesList =
        await ExerciseRepository.getOfSimilarNameAndAttributes(exercisePayload)
    }
    return exercisesList
  }

  async tryToFindTheMostSimilarExercise(
    chatgptWorkoutItem: ChatgptExerciseItem,
  ) {
    chatgptWorkoutItem.findingMostSimilarExercise = true

    const model = await getModel()
    if (!model) return

    const promptText = await this.createTagsPromptText(chatgptWorkoutItem.name)
    const [tagsResponse, e] = await generateCompletion(model.id, [
      { role: 'user', content: promptText },
    ])
    if (tagsResponse) {
      const tagIds = tagsResponse.split(',').map((t) => t.trim())
      const possibleExercises = await ExerciseRepository.getByTagIds({
        tagIds,
        hasWeight: !!chatgptWorkoutItem.weight,
        hasReps: !!chatgptWorkoutItem.reps,
        hasTime: !!chatgptWorkoutItem.time_seconds,
        hasResistanceBands: !!chatgptWorkoutItem.bandResistance,
      })

      const exercisesPromptText = await this.createExercisesPromptText(
        chatgptWorkoutItem.name,
        possibleExercises.map((e) => `(${e.id}, ${e.name})`).join(','),
      )

      const [exerciseText, e] = await generateCompletion(model.id, [
        { role: 'user', content: exercisesPromptText },
      ])
      if (exerciseText) {
        const [exerciseId, isAlternateExerciseChoice] = exerciseText.split('\n')

        const parsedExerciseId = parseInt(exerciseId)
        const mostSimilarExercise = possibleExercises.find(
          (x) => x.id === parsedExerciseId,
        )
        if (!mostSimilarExercise) {
          chatgptWorkoutItem.errorWhileFindingMostSimilarExercise =
            'Chatgpt api neatgrieza derīgu vingrojuma ID. atgrieztais ID: ' +
            exerciseId
          chatgptWorkoutItem.findingMostSimilarExercise = false
          return
        }
        if (!['A', 'B'].some((x) => x === isAlternateExerciseChoice)) {
          chatgptWorkoutItem.errorWhileFindingMostSimilarExercise =
            'Chatgpt api neatgrieza derīgu atbildi uz jautājumu vai vingrojums ir alternatīvs nosaukums, vai cits vingrojums.\nAtgrieztais atbildes teksts: ' +
            isAlternateExerciseChoice +
            ' (derīgas atbildes: A vai B)'
          chatgptWorkoutItem.findingMostSimilarExercise = false
          return
        }
        chatgptWorkoutItem.mostSimilarExerciseChoice =
          isAlternateExerciseChoice as 'A' | 'B'

        const isAlternateExerciseName = isAlternateExerciseChoice === 'A'
        if (isAlternateExerciseName) {
          await ExerciseRepository.addAlternateExerciseName(
            mostSimilarExercise.id,
            chatgptWorkoutItem.name,
          )
        }

        const givenExerciseAttributes: Record<
          string,
          string | number | boolean
        > = {}
        if (chatgptWorkoutItem.reps) {
          givenExerciseAttributes.reps = chatgptWorkoutItem.reps
        }
        if (chatgptWorkoutItem.weight) {
          givenExerciseAttributes.weight = chatgptWorkoutItem.weight
        }
        if (chatgptWorkoutItem.rpe) {
          givenExerciseAttributes.rpe = chatgptWorkoutItem.rpe
        }
        if (chatgptWorkoutItem.time_seconds) {
          givenExerciseAttributes.time_seconds = chatgptWorkoutItem.time_seconds
        }
        if (chatgptWorkoutItem.bandResistance) {
          givenExerciseAttributes.bandResistance =
            chatgptWorkoutItem.bandResistance
        }
        OpenaiApiFoundExerciseRepository.addAlternateExerciseName({
          given_exercise_name: chatgptWorkoutItem.name,
          given_exercise_attributes: JSON.stringify(givenExerciseAttributes),
          found_exercise_id: mostSimilarExercise.id,
          request: exercisesPromptText,
          response: exerciseText,
        })

        const exercisesList =
          await ExerciseRepository.getOfSimilarNameAndAttributes([
            {
              userId: this.userId,
              id: chatgptWorkoutItem.id,
              name: mostSimilarExercise.name,
              rpe: chatgptWorkoutItem.rpe,
              weight: chatgptWorkoutItem.weight,
              hasWeight: mostSimilarExercise.has_weight,
              hasReps: mostSimilarExercise.has_reps,
              hasTime: mostSimilarExercise.has_time,
              hasResistanceBands: mostSimilarExercise.has_resistance_bands,
            },
          ])

        chatgptWorkoutItem.models = exercisesList![chatgptWorkoutItem.id].map(
          (x) => transformSimilarNameResponse(x, chatgptWorkoutItem),
        )
      } else if (e) {
        console.error(e)
        chatgptWorkoutItem.errorWhileFindingMostSimilarExercise =
          getApiErrorMessage(e)
      }
    } else if (e) {
      console.error(e)
      chatgptWorkoutItem.errorWhileFindingMostSimilarExercise =
        getApiErrorMessage(e)
    }

    chatgptWorkoutItem.findingMostSimilarExercise = false
  }

  async createTagsPromptText(exerciseName: string) {
    const promptText = await VariousPromptsRepository.createForUser({
      key: 'find_exercise_tags',
      userId: this.userId,
      variables: { exercise_name: exerciseName },
    })
    return promptText.result
  }

  async mapChatgptWorkoutItem(
    chatgptWorkoutItem: ChatgptExerciseItem,
    exercisesList: SimilarNameResponse,
    pause: ExerciseVM,
  ) {
    if (chatgptWorkoutItem.is_pause) {
      chatgptWorkoutItem.models = [
        {
          exercise: pause,
          exercisesFromProgressionChain: null,
          bestExerciseFromProgressionChain: null,
          progressionChainAlreadyAdded: false,
        },
      ]
      return
    }

    try {
      const exercises = exercisesList![chatgptWorkoutItem.id]
      if (exercises?.length) {
        chatgptWorkoutItem.models = exercises.map((m) =>
          transformSimilarNameResponse(
            {
              ...m,
              exercise: ExerciseVM.createFrom(m.exercise),
            },
            chatgptWorkoutItem,
          ),
        )
      } else {
        const maxRetries = 3
        let triedTimes = 0

        await this.tryToFindTheMostSimilarExercise(chatgptWorkoutItem)
        while (
          chatgptWorkoutItem.errorWhileFindingMostSimilarExercise &&
          triedTimes < maxRetries
        ) {
          chatgptWorkoutItem.errorWhileFindingMostSimilarExercise = null
          await this.tryToFindTheMostSimilarExercise(chatgptWorkoutItem)
          triedTimes++
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export function transformSimilarNameResponse(
  similarNameResponse: SimilarNameResponse[number][number],
  chatgptWorkoutItem: ChatgptExerciseItem,
) {
  if (
    !similarNameResponse.exercisesFromProgressionChain ||
    !chatgptWorkoutItem.rpe
  ) {
    return similarNameResponse
  }

  similarNameResponse.bestExerciseFromProgressionChain =
    similarNameResponse.exercisesFromProgressionChain.reduce(
      (previous: ExerciseFromProgressionChain | null, current) => {
        if (!previous) return current
        return (previous.exerciseAppearsInLastWorkout &&
          !current.exerciseAppearsInLastWorkout) ||
          Math.abs(current.reps - (chatgptWorkoutItem.reps ?? 0)) <
            Math.abs(previous.reps - (chatgptWorkoutItem.reps ?? 0))
          ? current
          : previous
      },
      null,
    )

  return similarNameResponse
}

let model: Model | null = null
let modelPromise: Promise<unknown> | null = null

async function getModel() {
  if (!model) {
    if (!modelPromise) {
      modelPromise = getLatestChatModel('gpt-4').then((m) => {
        model = m
        modelPromise = null
      })
    }
    await modelPromise
  }
  return model
}
