<template>
  <div class="table-container">
    <p :class="hasBackupWorkout ? 'text-success' : 'text-danger'">
      {{
        `Klientam ${
          hasBackupWorkout ? 'IR' : 'NAV'
        } izveidots rezerves treniņš!`
      }}
    </p>
    <table class="table table-bordered table-striped">
      <thead>
        <tr>
          <th scope="col">⛔</th>
          <th scope="col">Izveidošanas datums</th>
          <th scope="col">Apraksts</th>
          <th scope="col">Kad atvērts</th>
          <th scope="col">Vingrojumi un novērtējumi</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="workout in workoutsTransformed" :key="workout.id">
          <td>
            <p class="text-danger">
              Uzmanību! Spiežot šo pogu tiek neatgriezeniski dzēsts treniņš un
              visi tā vingrojumu novērtējumi!
            </p>
            <LoadingButton
              class="btn-danger"
              :loading="deletingWorkout"
              @click="() => deleteWorkout(workout.id)"
            >
              Dzēst treniņu
            </LoadingButton>
          </td>
          <td class="text-center ws-nowrap">
            <div>{{ workout.created_at }}</div>

            <div v-if="workout.is_draft" class="mt-16">
              <div class="text-danger ws-normal">
                Šis treniņš ir melnraksts - tas vēl klientam nav redzams!
              </div>
              <LoadingButton
                :loading="publishingDraft"
                @click="() => publishWorkoutDraft(workout.id)"
              >
                Piešķirt
              </LoadingButton>
            </div>

            <div v-if="workout.is_backup" class="mt-16">
              <div class="text-info ws-normal">
                Šis treniņš ir klienta rezerves treniņš (gadījumam, kad
                neizdodas automātiski izveidot un piešķirt treniņu)
              </div>
            </div>
          </td>
          <td>{{ workout.description }}</td>

          <td class="text-center ws-nowrap">
            {{ workout.opened_at ? workout.opened_at : 'Nav atvērts' }}
          </td>

          <td>
            <span v-if="workout.completed" class="abandoned-text text-success">
              Treniņš ir pabeigts!
            </span>
            <span v-if="workout.abandoned" class="abandoned-text text-danger">
              Šis treniņš tika pamests!
            </span>
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Nr.</th>
                  <th>Vingrojums</th>
                  <th>Piešķirtais RPE</th>
                  <th>Nogurums</th>
                  <th>Reizes</th>
                  <th>Laiks (sekundēs)</th>
                  <th>Svars (kg)</th>
                  <th>Papildus atribūti</th>
                  <th>Novērtējums</th>
                  <th>
                    Spējas (1RM / max reizes / max laiks) (spējas ņem vērā
                    nogurumu)
                  </th>
                  <th>Atvērts</th>
                  <th>Lietotāja piezīmes</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(workoutExercise, i) in workout.workoutExercises"
                  :key="i"
                >
                  <td>{{ i + 1 }}</td>
                  <td>
                    <a
                      :href="workoutExercise.linkToExercise"
                      v-html="workoutExercise.displayText"
                    ></a>
                  </td>
                  <td>{{ workoutExercise.assignedRpeText }}</td>
                  <td>{{ workoutExercise.tirednessText }}</td>
                  <td>
                    <div
                      v-if="workoutExercise.getAttribute('reps')"
                      class="ws-nowrap"
                    >
                      Piešķirtais {{ workoutExercise.eachSideText() }}:
                      {{ workoutExercise.getAttribute('reps') }}
                    </div>
                    <div v-else>-</div>
                    <div
                      v-if="workoutExercise.getAttribute('actual_reps')"
                      class="ws-nowrap"
                    >
                      Reālais {{ workoutExercise.eachSideText() }}:
                      {{ workoutExercise.getAttribute('actual_reps') }}
                    </div>
                  </td>

                  <td>
                    <template v-if="workoutExercise.exercise.is_pause">
                      {{ workoutExercise.getAttribute('time_seconds') }}
                    </template>
                    <template v-else>
                      <div
                        v-if="workoutExercise.getAttribute('time_seconds')"
                        class="ws-nowrap"
                      >
                        Piešķirtais:
                        {{ workoutExercise.getAttribute('time_seconds') }}&nbsp;
                        {{ workoutExercise.eachSideText(false) }}
                      </div>
                      <div v-else>-</div>
                      <div
                        v-if="
                          workoutExercise.getAttribute('actual_time_seconds')
                        "
                        class="ws-nowrap"
                      >
                        Reālais:
                        {{
                          workoutExercise.getAttribute('actual_time_seconds')
                        }}
                        {{ workoutExercise.eachSideText(false) }}
                      </div>
                    </template>
                  </td>

                  <td>
                    <div
                      v-if="workoutExercise.getAttribute('weight')"
                      class="ws-nowrap"
                    >
                      Piešķirtais:
                      {{ workoutExercise.getAttribute('weight') }}
                    </div>
                    <div v-else>-</div>
                    <div
                      v-if="workoutExercise.actualWeightText"
                      class="ws-nowrap"
                    >
                      Programmas piekoriģētais vai aizstātais:
                      {{ workoutExercise.actualWeightText }}
                    </div>
                    <div
                      v-if="workoutExercise.getAttribute('chosen_weight')"
                      class="ws-nowrap"
                    >
                      Klienta izvēlētais:
                      {{ workoutExercise.getAttribute('chosen_weight') }}
                    </div>
                  </td>
                  <td>
                    <div
                      v-for="(
                        extraAttributeString, j
                      ) in workoutExercise.getExtraAttributeTexts()"
                      :key="j"
                    >
                      <p>{{ extraAttributeString }}</p>
                    </div>
                    <div
                      v-if="!workoutExercise.getExtraAttributeTexts().length"
                    >
                      -
                    </div>
                  </td>

                  <td>
                    <span v-html="workoutExercise.evaluationHtml"></span>
                    <span v-if="workoutExercise.executedRepsText">
                      (Tika izpildītas
                      <strong>{{ workoutExercise.executedRepsText }}</strong>
                      reizes)
                    </span>
                  </td>
                  <td>{{ workoutExercise.abilitiesRangeText }}</td>
                  <td>{{ workoutExercise.opened_at }}</td>
                  <td>{{ workoutExercise.userNotesText }}</td>
                </tr>
              </tbody>
            </table>
            <div v-if="!workout.abandoned">
              <h4>Ziņa trenerim:</h4>
              <div v-if="workout.messageForCoach">
                <div class="message-for-coach-container">
                  <div v-if="workout.messageForCoach.video" class="mw-300">
                    <video
                      id="post-workout-message-video"
                      class="w-full"
                      controls
                      data-role="player"
                      playsinline
                    >
                      <source
                        :src="'/' + workout.messageForCoach.video"
                        :type="
                          'video/' +
                          getFileVideoOrAudioType(workout.messageForCoach.video)
                        "
                      />
                    </video>
                  </div>

                  <div>
                    <p v-if="workout.messageForCoach.text">
                      {{ workout.messageForCoach.text }}
                    </p>
                    <div v-if="workout.messageForCoach.audio" class="mw-300">
                      <audio
                        id="post-workout-message-audio"
                        class="w-full"
                        controls
                        data-role="player"
                      >
                        <source
                          :src="'/' + workout.messageForCoach.audio"
                          :type="
                            'audio/' +
                            getFileVideoOrAudioType(
                              workout.messageForCoach.audio,
                            )
                          "
                        />
                      </audio>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else><em>nav ievadīta</em></div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import WorkoutRepository from '@common/repositories/WorkoutRepository'
import LoadingButton from './LoadingButton.vue'
import { Workout } from '../types/Workout'
import WorkoutExerciseDisplayVM from '../models/WorkoutExerciseDisplayVM'

const props = defineProps<{
  workouts: Workout[]
  hasBackupWorkout: boolean
}>()

const emit = defineEmits<{ (e: 'reload-user-workouts'): void }>()

const workoutsTransformed = computed(() =>
  props.workouts.map((workout) => ({
    ...workout,
    workoutExercises: workout.workoutExercises.map(
      (we) => new WorkoutExerciseDisplayVM(we),
    ),
  })),
)

const getFileVideoOrAudioType = (fileString: string) => {
  const ext = fileString.split('.').pop()
  return ext === 'mov' ? 'quicktime' : ext
}

const publishingDraft = ref(false)
const publishWorkoutDraft = async (workoutId: number) => {
  publishingDraft.value = true
  try {
    await WorkoutRepository.publishDraft(workoutId)
    emit('reload-user-workouts')
  } catch (e: any) {
    console.error(e)
  } finally {
    publishingDraft.value = false
  }
}

const deletingWorkout = ref(false)
const deleteWorkout = async (workoutId: number) => {
  try {
    await WorkoutRepository.delete(workoutId)
    emit('reload-user-workouts')
  } catch (e) {
    console.error(e)
  } finally {
    deletingWorkout.value = false
  }
}
</script>

<style scoped lang="scss">
.ws-nowrap {
  white-space: nowrap;
}
.ws-normal {
  white-space: normal;
}
.mt-16 {
  margin-top: 16px;
}
.abandoned-text {
  line-height: 2.5;
  font-weight: bold;
}
.message-for-coach-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.mw-300 {
  max-width: 300px;
}
.w-full {
  width: 100%;
}

.table-container {
  margin-top: 16px;
}
</style>
