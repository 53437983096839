import Repository from '@common/repositories/Repository'
import axios from 'axios'

export type ClientDetails = {
  id: number
  user_id: number
  bodyweight: number | null
  goal: string | null
  experience: string | null
  problems: string | null
  notes: string | null
  preferences: string | null
  desired_weekly_workouts_count: number | null
  desired_workout_length: number | null
  accessible_equipment: string | null
  activity_level: string
  default_workout_prompt_id: number
  defaultWorkoutPrompt: Record<string, unknown>
  default_exercise_set_id: number | null
}

export default class ClientDetailsRepository extends Repository {
  static get baseUrl() {
    return '/client-details'
  }

  static async getByUser(userId: number) {
    return (
      await axios.get<ClientDetails>(`${this.baseUrl}/api-get-by-user`, {
        params: { userId },
      })
    ).data
  }

  static update(userId: number | null, data: Partial<ClientDetails>) {
    return axios.post(`${this.baseUrl}/api-update`, { userId, data })
  }
}
