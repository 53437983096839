import Repository from '@common/repositories/Repository'
import axios from 'axios'

export default class TemplateRepository extends Repository {
  static get baseUrl() {
    return '/template'
  }

  static async list() {
    return (await axios.get(`${this.baseUrl}/api-list`)).data
  }

  static async get(templateId: number) {
    return (
      await axios.get(`${this.baseUrl}/api-get`, { params: { id: templateId } })
    ).data
  }

  static async create(template) {
    return axios.post(`${this.baseUrl}/create`, template)
  }

  static async update(templateId, template) {
    return axios.patch(`${this.baseUrl}/update?id=${templateId}`, template)
  }
}
