import { computed, Ref, ref } from 'vue'
import usePauseStore from 'trainer/src/stores/usePauseStore'
import {
  ChatgptExerciseItem,
  ChatgptWorkoutItem,
} from '@common/repositories/ExerciseRepository'
import { WorkoutMainData } from '@common/helpers/chatgpt'
import ExerciseVM from 'trainer/src/models/ExerciseVM'
import { roundedToFixed } from 'trainer/src/helpers/Math'

export default function useChatgptWorkoutStatistics(
  chatgptWorkoutData: Ref<ChatgptWorkoutItem[] | null>,
) {
  const pauseStore = usePauseStore()

  const groupedChatgptWorkoutData = computed(() => {
    if (!chatgptWorkoutData.value) return null

    const groupedItems: ChatgptWorkoutItem[][] = []
    let currentSectionItems: ChatgptWorkoutItem[] = []

    for (const item of chatgptWorkoutData.value) {
      if ('sectionTitle' in item) {
        if (currentSectionItems.length > 0) {
          groupedItems.push(currentSectionItems)
        }
        currentSectionItems = [item]
      } else {
        currentSectionItems.push(item)
      }
    }

    if (currentSectionItems.length > 0) {
      groupedItems.push(currentSectionItems)
    }

    return groupedItems
  })

  const loadingChatgptExercisesCount = computed(() =>
    chatgptWorkoutData.value?.filter(
      (x) =>
        (!('notExercise' in x) && !x.modelsLoaded) ||
        ('findingMostSimilarExercise' in x && x.findingMostSimilarExercise),
    ),
  )
  const loadedChatgptExercisesCount = computed(() =>
    chatgptWorkoutData.value?.filter(
      (x) =>
        !('notExercise' in x) &&
        x.modelsLoaded &&
        !x.findingMostSimilarExercise,
    ),
  )

  const chatgptWorkoutLoading = computed(
    () =>
      loadingChatgptExercisesCount.value?.length &&
      loadingChatgptExercisesCount.value.length > 0,
  )

  const chatgptDataStatistics = computed(() => {
    if (!chatgptWorkoutData.value) return null

    const exerciseItems = (loadedChatgptExercisesCount.value ?? []).filter(
      (x) => !('notExercise' in x),
    ) as ChatgptExerciseItem[]

    const withOneExercise = exerciseItems.filter(
      (x) => x.is_pause || x.models?.length === 1,
    ).length
    const withNoExercises = exerciseItems.filter(
      (x) => !x.is_pause && x.models?.length === 0,
    ).length

    return {
      inTotal: chatgptWorkoutData.value.filter((x) => !('notExercise' in x))
        .length,
      loading: loadingChatgptExercisesCount.value?.length,
      withOneExercise,
      withNoExercises,
      withManyExercises:
        exerciseItems.length - withOneExercise - withNoExercises,
    }
  })

  type Option = {
    exercise: ExerciseVM
    initialData: WorkoutMainData
  }
  type ExercisesToBeAddedResponse = Array<{
    pickedIndex: Ref<number>
    options: Option[]
    isLast?: boolean
  }>

  const exercisesToBeAdded = computed(() => {
    if (!groupedChatgptWorkoutData.value || !pauseStore.pause) return []
    let toAddTimes = 1
    return groupedChatgptWorkoutData.value
      .map((sectionItems) => {
        const itemsToAdd = sectionItems
          .map((x) => {
            if (!x || 'description' in x) return
            if ('sectionTitle' in x) {
              toAddTimes = x.rounds
              return
            }

            let options: Option[] = []

            if (x.is_pause) {
              options = [
                {
                  exercise: pauseStore.pause!,
                  initialData: {
                    name: pauseStore.pause!.name,
                    time_seconds: x.time_seconds,
                  },
                },
              ]
            } else if (x.models) {
              options = x.models
                .map((m) => {
                  if (m.bestExerciseFromProgressionChain) {
                    return {
                      exercise: m.bestExerciseFromProgressionChain.exercise,
                      initialData: {
                        ...x,
                        name: m.bestExerciseFromProgressionChain.exercise.name,
                        reps: parseFloat(
                          roundedToFixed(
                            m.bestExerciseFromProgressionChain.reps,
                            1,
                          ),
                        ),
                      } as WorkoutMainData,
                    }
                  } else if (!m.progressionChainAlreadyAdded) {
                    if (
                      m.exerciseAppearsInLastWorkout &&
                      (x.models ?? []).filter(
                        (y) => !y.exerciseAppearsInLastWorkout,
                      ).length > 0
                    ) {
                      return
                    }

                    return {
                      exercise: m.exercise,
                      initialData: {
                        ...x,
                        reps: m.exerciseReps ?? x.reps,
                        weight: m.exerciseWeight ?? x.weight,
                      } as WorkoutMainData,
                    }
                  }
                })
                .filter(Boolean)
            }

            return {
              pickedIndex: ref(0),
              options,
            }
          })
          .filter(Boolean)

        const res: ExercisesToBeAddedResponse = []
        for (let i = 0; i < toAddTimes; i++) res.push(...itemsToAdd)
        if (res[res.length - 1]) res[res.length - 1].isLast = true
        return res
      })
      .flat()
      .filter(Boolean)
  })

  return {
    exercisesToBeAdded,
    groupedChatgptWorkoutData,
    chatgptDataStatistics,
    loadingChatgptExercisesCount,
    chatgptWorkoutLoading,
  }
}
