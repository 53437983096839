export const clamp = (value: number, limit1: number, limit2: number) => {
  if ([value, limit1, limit2].some(Number.isNaN)) return NaN

  if (limit1 > limit2) {
    ;[limit1, limit2] = [limit2, limit1]
  }
  const min = (value1: number, value2: number): number => {
    return value1 < value2 ? value1 : value2
  }
  const max = (value1: number, value2: number): number => {
    return value1 > value2 ? value1 : value2
  }

  return min(max(value, limit1), limit2)
}

export const findClosestNumber = <T extends number>(
  haystack: T[],
  needle: T
) => {
  return haystack.reduce((prev, curr) =>
    Math.abs(curr - needle) < Math.abs(prev - needle) ? curr : prev
  )
}

export const roundedToFixed = (input: number, digits: number) => {
  const rounder = Math.pow(10, digits)
  return (Math.round(input * rounder) / rounder).toFixed(digits)
}

export const roundToHalf = (value: number) => Math.round(value * 2) / 2
