import Repository from '@common/repositories/Repository'
import axios from 'axios'

export default class UserRepository extends Repository {
  static get baseUrl() {
    return '/user'
  }

  static async get(id: number) {
    return (await axios.get(`/user/api-get?id=${id}`)).data
  }

  static async deleteAllWorkouts(id: number) {
    await axios.post(`/user/api-delete-all-workouts?id=${id}`, {})
  }
}
