import ExerciseVM from './ExerciseVM'
import { formatAbilityRange, Rpe } from '../helpers/Global'
import WorkoutExerciseVM from './WorkoutExerciseVM'
import { roundedToFixed, roundToHalf } from '../helpers/Math'

export default class WorkoutExerciseDisplayVM {
  // @ts-ignore
  exercise: ExerciseVM
  reps: number | null = null
  actual_reps: number | null = null
  executed_reps: number | null = null
  weight: number | null = null
  actual_weight: number | null = null
  chosen_weight: number | null = null
  time_seconds: number | null = null
  actual_time_seconds: number | null = null
  resistance_bands: { value: number; label: string } | number | null = null
  mode: number | null = null
  incline_percent: number | null = null
  pace_min_km: number | null = null
  speed_km_h: number | null = null
  distance_m: number | null = null
  pulse: number | null = null
  height: number | null = null
  assigned_rpe: Rpe | null = null
  is_skipped = false
  skipped_reason: string | null = null
  tiredness_percentage = 0
  user_notes: string | null = null
  opened_at = false

  evaluation?: {
    evaluation_text?: string
    evaluation_rpe_range?: [number, number]
    one_rep_max_range?: string
    max_reps_range?: string
    max_time_seconds_range?: string
    max_kg_sec_range?: string
  }
  replacementExercise?: any

  extraAttributes = [
    {
      attribute: 'resistance_bands',
      labels: { lv: 'Gumiju pretestība', en: 'Resistance band resistance' },
    },
    {
      attribute: 'mode',
      labels: { lv: 'Režīms', en: 'Mode' },
    },
    {
      attribute: 'incline_percent',
      labels: { lv: 'Slīpums (%)', en: 'Incline (%)' },
    },
    {
      attribute: 'pace',
      labels: { lv: 'Temps (min/km)', en: 'Pace (min/km)' },
    },
    {
      attribute: 'speed',
      labels: { lv: 'Ātrums (km/h)', en: 'Speed (km/h)' },
    },
    {
      attribute: 'distance',
      labels: { lv: 'Attālums (m)', en: 'Distance (m)' },
    },
    {
      attribute: 'pulse',
      labels: { lv: 'Pulss', en: 'Pulse' },
    },
    {
      attribute: 'height',
      labels: { lv: 'Augstums (cm)', en: 'Height (cm)' },
    },
  ]

  /** keep in sync with the backend */
  resistanceBands = [
    { value: 1, labels: { lv: 'Maza', en: 'Small' } },
    { value: 2, labels: { lv: 'Vidēja', en: 'Medium' } },
    { value: 3, labels: { lv: 'Liela', en: 'Large' } },
    { value: 4, labels: { lv: 'Maza + Liela', en: 'Small + Large' } },
    { value: 5, labels: { lv: 'Vidēja + Liela', en: 'Medium + Large' } },
    {
      value: 6,
      labels: { lv: 'Maza + Vidēja + Liela', en: 'Small + Medium + Large' },
    },
  ]

  constructor(workoutExercise: WorkoutExerciseVM) {
    Object.assign(this, workoutExercise)
  }

  get linkToExercise() {
    const id = this.replacementExercise
      ? this.replacementExercise.exercise_id
      : this.exercise.id
    return `/exercise/${id}`
  }

  get displayText() {
    let text = this.replacementExercise
      ? `${this.replacementExerciseNameWithFallback} (aizstāja oriģinālo vingrojumu ${this.exerciseNameWithFallback})`
      : this.exerciseNameWithFallback
    if (this.is_skipped) {
      text += `</br><span class="text-danger">Vingrojums tika izlaists!</span> <span>(Iemesls: ${this.skipped_reason})</span>`
    }

    return text
  }

  get executedExerciseName() {
    return this.replacementExercise
      ? this.replacementExerciseNameWithFallback
      : this.exerciseNameWithFallback
  }

  get exerciseNameWithFallback() {
    return this.exercise
      ? '<strong>' + this.exercise.name + '</strong>'
      : '(<em>dzēsts vingrojums<em>)'
  }

  get replacementExerciseNameWithFallback() {
    return this.replacementExercise
      ? '<strong>' + this.replacementExercise.exercise.name + '</strong>'
      : '(<em>dzēsts vingrojums<em>)'
  }

  get assignedRpeText() {
    return this.assigned_rpe ? this.assigned_rpe : '-'
  }

  get tirednessText() {
    return !this.exercise.is_pause ? `${this.tiredness_percentage}%` : '-'
  }

  get actualWeightText() {
    return this.replacementExercise
      ? this.replacementExercise.weight
      : this.actual_weight
      ? this.actual_weight
      : this.weight
  }

  get actualRepsText() {
    return this.replacementExercise
      ? this.replacementExercise.reps
      : this.actual_reps
      ? this.actual_reps
      : this.reps
  }

  get executedRepsText() {
    if (this.executed_reps) return this.executed_reps
    if (this.replacementExercise?.executed_reps)
      return this.replacementExercise.executed_reps
    return null
  }

  getAttribute(attribute: keyof typeof this) {
    return this.replacementExercise
      ? this.replacementExercise[attribute]
      : this[attribute]
  }

  getExtraAttributeTexts(
    labelLang: keyof (typeof this.extraAttributes)[number]['labels'] = 'lv'
  ) {
    return this.extraAttributes
      .map((extraAttribute) => {
        const exercise = this.replacementExercise
          ? this.replacementExercise
          : this
        if (!exercise[extraAttribute.attribute]) return null
        const value = exercise[extraAttribute.attribute]
        if (extraAttribute.attribute === 'resistance_bands') {
          const x = this.resistanceBands.find(
            (x) => x.value === exercise[extraAttribute.attribute]
          )
          if (!x) {
            console.log(
              x,
              exercise[extraAttribute.attribute],
              this.resistanceBands
            )
          }
          // value = x.labels[labelLang]
        }
        return `${extraAttribute.labels[labelLang]}: ${value}`
      })
      .filter(Boolean)
  }

  get avgEvaluationRpe() {
    if (!this.evaluation?.evaluation_rpe_range) return null
    return (
      (this.evaluation.evaluation_rpe_range[0] +
        this.evaluation.evaluation_rpe_range[1]) /
      2
    )
  }

  get assignedAndEvaluationRpeDifference() {
    if (!this.avgEvaluationRpe || !this.assigned_rpe) return null
    return roundToHalf(this.assigned_rpe - this.avgEvaluationRpe)
  }

  get evaluationHtml() {
    if (!this.evaluation) return '-'
    let res = this.evaluation.evaluation_text

    if (this.evaluation.evaluation_rpe_range && this.avgEvaluationRpe) {
      const min = roundedToFixed(this.evaluation.evaluation_rpe_range[0], 2)
      const max = roundedToFixed(this.evaluation.evaluation_rpe_range[1], 2)

      const range = parseFloat(min) === parseFloat(max) ? min : `${min}-${max}`

      res += `</br>(RPE: ${range}`
      res += ` | ${this.assignedAndEvaluationRpeDifference ?? ''})`
    }

    return res
  }

  get rpeDifferenceText() {
    const difference = this.assignedAndEvaluationRpeDifference
    if (!difference) return null
    if (difference >= 2) {
      return 'The exercise was way too easy'
    } else if (difference >= 1) {
      return 'The exercise was a little too easy'
    } else if (difference > -1) {
      return 'The exercise intensity was perfect'
    } else if (difference > -2) {
      return 'The exercise was a little too hard'
    } else {
      return 'The exercise was way too hard'
    }
  }

  get abilitiesRangeText() {
    if (!this.evaluation) return '-'

    if (this.evaluation.one_rep_max_range) {
      return formatAbilityRange(this.evaluation.one_rep_max_range, 'kg')
    }
    if (this.evaluation.max_kg_sec_range) {
      return formatAbilityRange(this.evaluation.max_kg_sec_range, 'kgsec')
    }
    if (this.evaluation.max_reps_range) {
      return formatAbilityRange(this.evaluation.max_reps_range, 'r')
    }
    if (this.evaluation.max_time_seconds_range) {
      return formatAbilityRange(this.evaluation.max_time_seconds_range, 's')
    }

    return ''
  }

  get userNotesText() {
    return this.user_notes ? this.user_notes : '-'
  }

  eachSideText(forWeight = true) {
    const exercise = (
      this.replacementExercise ? this.replacementExercise : this
    ).exercise
    return (
      forWeight
        ? exercise.weight_is_for_each_side
        : exercise.time_is_for_each_side
    )
      ? '(katrai rokai/pusei)'
      : ''
  }
}
