<template>
  <div class="outer">
    <div class="inner" :style="{ width: `${passedTimePerc}%` }"></div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, ref } from 'vue'

const props = defineProps<{ timeSeconds: number }>()

const t = ref(props.timeSeconds)

let interval: ReturnType<typeof setInterval>

const startProgress = () => {
  interval = setInterval(() => {
    if (t.value > 0) t.value -= 0.1
  }, 100)
}

const passedTimePerc = computed(
  () => ((props.timeSeconds - t.value) / props.timeSeconds) * 100
)

onBeforeUnmount(() => {
  if (interval) clearInterval(interval)
})

defineExpose({ startProgress })
</script>

<style scoped lang="scss">
.outer {
  margin-top: 8px;
  height: 20px;
  background: white;
  border-radius: 12px;
  border: 1px solid var(--gray);
}

.inner {
  background: var(--normal-green);
  height: 100%;
  border-radius: 12px;
}
</style>
