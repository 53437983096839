export const getCsrfToken = (): string | null => {
  const csrfElement = document.querySelector(
    'meta[name=csrf-token]',
  ) as HTMLMetaElement | null
  if (!csrfElement) {
    console.warn('No csrf element on page, falling back to window object')
    //@ts-ignore
    return window.clientSpa?.csrfToken ?? null
  }
  return csrfElement.content
}
