import Repository from '@common/repositories/Repository'
import axios, { AxiosRequestConfig } from 'axios'
import ExerciseVM from 'trainer/src/models/ExerciseVM'
import { WorkoutMainData } from '../helpers/chatgpt'

export type ExerciseFromProgressionChain = {
  exercise: ExerciseVM
  reps: number
  exerciseAppearsInLastWorkout: boolean
}

export type SimilarNameResponse = Array<
  Array<{
    exercise: ExerciseVM
    exerciseAppearsInLastWorkout?: boolean
    exerciseWeight?: number
    exerciseReps?: number
    exercisesFromProgressionChain: ExerciseFromProgressionChain[] | null
    bestExerciseFromProgressionChain: ExerciseFromProgressionChain | null
    progressionChainAlreadyAdded: boolean
  }>
>

export type ChatgptExerciseItem = WorkoutMainData & {
  id: number
  models?: SimilarNameResponse[number]
  modelsLoaded?: boolean
  findingMostSimilarExercise?: boolean
  errorWhileFindingMostSimilarExercise?: string | null
  mostSimilarExerciseChoice?: 'A' | 'B'
}

export type ChatgptSectionTitleItem = {
  id: number
  sectionTitle: string
  rounds: number
  notExercise: true
}

export type ChatgptWorkoutDescriptionItem = {
  id: number
  description: string
  notExercise: true
}

export type ChatgptWorkoutItem =
  | ChatgptExerciseItem
  | ChatgptSectionTitleItem
  | ChatgptWorkoutDescriptionItem

export default class ExerciseRepository extends Repository {
  static get baseUrl() {
    return '/exercise'
  }

  static async list(config: AxiosRequestConfig) {
    return (await axios.get(`${this.baseUrl}/api-list`, config)).data.map(
      ExerciseVM.createFrom,
    )
  }

  static async getPause() {
    return ExerciseVM.createFrom(
      (await axios.get(`${this.baseUrl}/api-get-pause`)).data,
    )
  }

  static async getOfSimilarNameAndAttributes(
    data: Array<{
      userId: number
      id: number
      name: string
      rpe?: number
      weight?: number
      hasWeight: boolean
      hasReps: boolean
      hasTime: boolean
      hasResistanceBands: boolean
    }>,
  ): Promise<SimilarNameResponse> {
    return (
      await axios.post(
        `${this.baseUrl}/api-get-of-similar-name-and-attributes`,
        data,
      )
    ).data
  }

  static async create(exercise) {
    return (await axios.post(`${this.baseUrl}/api-create`, exercise)).data
  }

  static async getAverageAbility(id: number, userId: number) {
    return (
      await axios.get(`${this.baseUrl}/api-get-average-ability`, {
        params: { id, userId },
      })
    ).data
  }

  static async getByTagIds(params: {
    tagIds: string[]
    hasWeight: boolean
    hasReps: boolean
    hasTime: boolean
    hasResistanceBands: boolean
  }) {
    return (
      await axios.get(`${this.baseUrl}/api-get-by-tag-ids`, { params })
    ).data.map(ExerciseVM.createFrom) as ExerciseVM[]
  }

  static async addAlternateExerciseName(
    id: number,
    alternateExerciseName: string,
  ) {
    return (
      await axios.post(`${this.baseUrl}/api-add-alternate-exercise-name`, {
        id,
        alternateExerciseName,
      })
    ).data
  }
}
