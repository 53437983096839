import Repository from '@common/repositories/Repository'
import axios from 'axios'

export default class TagRepository extends Repository {
  static get baseUrl() {
    return '/tag'
  }

  static async list() {
    return (await axios.get(`${this.baseUrl}/api-list`)).data
  }

  static async listTypeSelectOptions() {
    return (await axios.get(`${this.baseUrl}/api-list-type-select-options`))
      .data
  }
}
