<template>
  <div class="timer-container timer-container--double">
    <div class="double-timer-outer">
      <div class="timer-wrapper">
        <ClientBaseTimer
          ref="firstTimerRef"
          has-countdown
          :start-instantly-countdown-seconds="8"
          :time="time"
          @finished="onFirstTimerFinished"
        />
      </div>
      <div class="timer-wrapper">
        <ClientBaseTimer
          ref="secondTimerRef"
          :can-be-started="firstTimerRef?.isFinished ?? false"
          has-countdown
          :time="time"
          @finished="onSecondTimerFinished"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ClientBaseTimer from './ClientBaseTimer.vue'
import { Ref, ref } from 'vue'

const props = defineProps<{
  time: number
  redirectWhenFinished: boolean
  continueBtnHref: string
}>()

const firstTimerRef = ref<{
  start: () => void
  isFinished: Ref<boolean>
} | null>(null)
const secondTimerRef = ref<{
  start: () => void
  isFinished: Ref<boolean>
} | null>(null)

const onFirstTimerFinished = () => {
  if (secondTimerRef.value) secondTimerRef.value.start()
}

const onSecondTimerFinished = () => {
  if (props.redirectWhenFinished && props.continueBtnHref) {
    window.location.href = props.continueBtnHref
  }
}
</script>

<style scoped lang="scss">
.timer-container--double {
  padding: 8px 16px;
}

.double-timer-outer {
  display: flex;
  justify-content: space-around;
}

.timer-wrapper {
  flex-basis: 50%;
}
</style>
