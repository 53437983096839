import { MAX_RPE } from './Global'

// TODO: update naming just like in RpeCalculatorForRepsOrTime.php
export class RpeCalculatorForRepsOrTime {
  static readonly RPE_TO_PERC_OF_MAX_TIME_RATIO: number = 10

  static calculateRpe(percOfMaxTime: number): number {
    return percOfMaxTime * this.RPE_TO_PERC_OF_MAX_TIME_RATIO
  }

  static calculatePercOfMaxTime(rpe: number): number {
    return rpe / this.RPE_TO_PERC_OF_MAX_TIME_RATIO
  }

  static calculateTimeFromRpe(rpe: number, time: number): number {
    return (time / rpe) * MAX_RPE
  }

  static calculateTimeFromPercOfMaxTime(
    percOfMaxTime: number,
    time: number
  ): number {
    return time / percOfMaxTime
  }
}
