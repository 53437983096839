<template>
  <li>
    <a :href="linkToExercise(model.exercise)" target="_blank">
      <span v-if="modelCanBeAddedToWorkout"> {{ model.exercise.name }}</span>
      <s v-else> {{ model.exercise.name }}</s>
      <span v-if="model.exerciseAppearsInLastWorkout" class="text-danger">
        Vingrojums parādās pēdējā treniņā
      </span>
    </a>
    <button
      v-if="modelCanBeAddedToWorkout"
      class="btn btn-sm"
      :class="[isBestOption ? 'btn-success' : 'btn-primary']"
      style="margin-left: 8px"
      @click="
        (e) =>
          emit(
            'click:add-to-workout',
            e,
            model.exercise,
            model.exerciseReps ?? null,
            model.exerciseWeight ?? null,
          )
      "
    >
      Pievienot treniņam
    </button>
    <div v-if="model.exercisesFromProgressionChain">
      Vingrojumi no progresiju ķēdes:
      <table class="table table-bordered table-sm table-striped">
        <thead>
          <tr>
            <th>Vingrojums</th>
            <th>Spējas pie vajadzīgā RPE</th>
            <th>Parādās pēdējā treniņā</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(chainModel, j) in model.exercisesFromProgressionChain"
            :key="j"
          >
            <tr>
              <td :style="getChainModelCellStyle(chainModel)">
                {{ chainModel.exercise.name }}
              </td>
              <td :style="getChainModelCellStyle(chainModel)">
                {{ Math.round(chainModel.reps) }}
              </td>
              <td>
                <span
                  v-if="!chainModel.exerciseAppearsInLastWorkout"
                  class="text-success"
                >
                  Nē
                </span>
                <span v-else class="text-danger">Jā</span>
              </td>
              <td :style="getChainModelCellStyle(chainModel)">
                <button
                  class="btn btn-info btn-sm"
                  @click="
                    (e) =>
                      emit(
                        'click:add-to-workout',
                        e,
                        chainModel.exercise,
                        chatgptExerciseItem.reps,
                      )
                  "
                >
                  Pievienot treniņam
                </button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-else-if="model.progressionChainAlreadyAdded">
      <em>Progresiju ķēde jau parādās pie cita vingrojuma</em>
    </div>
  </li>
</template>

<script setup lang="ts">
import {
  ChatgptExerciseItem,
  ExerciseFromProgressionChain,
  SimilarNameResponse,
} from '@common/repositories/ExerciseRepository'
import ExerciseVM from '../models/ExerciseVM'
import { computed } from 'vue'

const props = defineProps<{
  model: SimilarNameResponse[number][number]
  chatgptExerciseItem: ChatgptExerciseItem
}>()

const emit = defineEmits<{
  'click:add-to-workout': [
    e: Event,
    exercise: ExerciseVM,
    reps?: number | null,
    weight?: number | null,
  ]
}>()

const isBestOption = computed(
  () =>
    !props.model.exerciseAppearsInLastWorkout ||
    (props.chatgptExerciseItem.models ?? []).filter(
      (x) => x.exerciseAppearsInLastWorkout,
    ).length === 0,
)

const modelCanBeAddedToWorkout = computed(
  () =>
    !props.model.bestExerciseFromProgressionChain &&
    !props.model.progressionChainAlreadyAdded &&
    isBestOption.value,
)

const linkToExercise = (exercise: ExerciseVM) =>
  `${window.origin}/exercise/${exercise.id}`

const getChainModelCellStyle = (chainModel: ExerciseFromProgressionChain) => {
  const isBest =
    chainModel.exercise.id ===
    props.model.bestExerciseFromProgressionChain?.exercise.id
  if (isBest) return { backgroundColor: 'lightgreen' }
}
</script>
