import { defineStore } from 'pinia'
import TagRepository from '../repositories/TagRepository'
import { ref } from 'vue'
import ExerciseVM from '../models/ExerciseVM'
import ExerciseRepository from '@common/repositories/ExerciseRepository'

export default defineStore('pause', () => {
  const pause = ref<ExerciseVM>()
  let tagsPromise: ReturnType<typeof TagRepository.list> | null

  const loadPause = async () => {
    try {
      tagsPromise = ExerciseRepository.getPause()
      pause.value = await tagsPromise
    } catch (e) {
      console.error('Error while loading pause: ', e)
    } finally {
      tagsPromise = null
    }
  }

  const awaitPauseLoaded = async () => {
    if (tagsPromise) await tagsPromise
  }

  loadPause()

  return { pause, awaitPauseLoaded }
})
