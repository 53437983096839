import RepMaxCalculatorBrzycki from './RepMaxCalculatorBrzycki'
import RepMaxCalculatorEpley from './RepMaxCalculatorEpley'
import { clamp } from './Math'

export default class RpeCalculatorForWeight {
  public static readonly MIN_RPE = 0
  public static readonly MAX_RPE = 10
  public static readonly MIN_REPS = 0
  public static readonly MIN_PERC_OF_ONE_REP_MAX = 0
  public static readonly MAX_PERC_OF_ONE_REP_MAX = 1

  public static readonly MIN_REPS_TO_USE_EPLEY = 10

  public static calculateOneRepMax(weight: number, reps: number) {
    if (reps === 1) return weight
    return reps < RpeCalculatorForWeight.MIN_REPS_TO_USE_EPLEY
      ? RepMaxCalculatorBrzycki.calculateOneRepMax(weight, reps)
      : RepMaxCalculatorEpley.calculateOneRepMax(weight, reps)
  }

  public static calculateMaxReps(rpe: number, reps: number) {
    if (rpe === 10) return reps
    const res =
      reps < this.MIN_REPS_TO_USE_EPLEY
        ? RepMaxCalculatorBrzycki.calculateMaxReps(rpe, reps)
        : RepMaxCalculatorEpley.calculateMaxReps(rpe, reps)
    return Math.max(Math.round(res), this.MIN_REPS)
  }

  public static calculatePercentageOfOneRepMax(rpe: number, reps: number) {
    const res =
      reps < this.MIN_REPS_TO_USE_EPLEY
        ? RepMaxCalculatorBrzycki.calculatePercentageOfOneRepMax(rpe, reps)
        : RepMaxCalculatorEpley.calculatePercentageOfOneRepMax(rpe, reps)
    return clamp(
      res,
      this.MIN_PERC_OF_ONE_REP_MAX,
      this.MAX_PERC_OF_ONE_REP_MAX
    )
  }

  public static calculateReps(rpe: number, percOf1RM: number) {
    if (rpe === 10 && percOf1RM === 1) return 1
    let res = RepMaxCalculatorBrzycki.calculateReps(rpe, percOf1RM)
    if (res >= this.MIN_REPS_TO_USE_EPLEY) {
      res = RepMaxCalculatorEpley.calculateReps(rpe, percOf1RM)
    }
    return Math.max(Math.round(res), this.MIN_REPS)
  }

  public static calculateRpe(reps: number, percOf1RM: number) {
    const res =
      reps < this.MIN_REPS_TO_USE_EPLEY
        ? RepMaxCalculatorBrzycki.calculateRpe(reps, percOf1RM)
        : RepMaxCalculatorEpley.calculateRpe(reps, percOf1RM)
    return clamp(res, this.MIN_RPE, this.MAX_RPE)
  }
}
