<template>
  <div class="base-timer">
    <svg
      class="base-timer__svg"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g class="base-timer__circle">
        <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45" />
        <path
          class="base-timer__path-remaining"
          d="M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0"
          :stroke-dasharray="circleDasharray"
        />
      </g>
    </svg>
    <span
      v-if="showCountdown"
      class="countdown-label"
      :style="{ color: countdownColor }"
    >
      {{ countdown }}
    </span>
    <span
      class="base-timer__label"
      :class="{ 'base-timer__label--inactive': showCountdown }"
    >
      {{ formatTimeLeft(timeLeft) }}
    </span>
  </div>
  <div v-if="!isFinished && canBeStarted" class="timer-buttons">
    <TimerButton
      :counting-down="!!(showCountdown && countdown)"
      :is-paused="isPaused"
      :is-started="isStarted"
      :start="start"
      :toggle-pause="togglePause"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch, onBeforeUnmount } from 'vue'
import shortBeepUrl from '@files/audio/beeps/beep-short.mp3'
import longBeepUrl from '@files/audio/beeps/beep-long.mp3'
import TimerButton from '../components/TimerButton.vue'

type COUNTDOWN_SECONDS = 5 | 8

const props = withDefaults(
  defineProps<{
    time: number
    canBeStarted?: boolean
    startInstantly?: boolean
    hasCountdown?: boolean
    startInstantlyCountdownSeconds?: COUNTDOWN_SECONDS
    isPause?: boolean
  }>(),
  { canBeStarted: true, startInstantlyCountdownSeconds: 5, isPause: false },
)

const emit = defineEmits<{ finished: [] }>()

const FULL_DASH_ARRAY = 283

const showCountdown = ref(false)
const countdown = ref(5)
const timePassed = ref(0)

const timeLeft = computed(() => props.time - timePassed.value)

watch(
  timeLeft,
  (n) => {
    // @ts-ignore
    window.clientTimerTimeLeft = n
  },
  { immediate: true },
)

const circleDasharray = computed(() => {
  const rawTimeFraction = timeLeft.value / props.time
  const timeFraction =
    rawTimeFraction - (1 / props.time) * (1 - rawTimeFraction)
  const progress = FULL_DASH_ARRAY - timeFraction * FULL_DASH_ARRAY
  return `${progress} ${FULL_DASH_ARRAY}`
})

const countdownColor = computed(() => {
  switch (countdown.value) {
    case 8:
    case 7:
    case 6:
    case 5:
    case 4:
      return '#85c70b'
    case 3:
    case 2:
      return '#e67e22'
    case 1:
      return '#cb0e0e'
    default:
      return ''
  }
})

const countdownInterval = ref<ReturnType<typeof setInterval> | null>(null)

const beep = new Audio(shortBeepUrl)
beep.volume = 0.01
const finalBeep = new Audio(longBeepUrl)
finalBeep.volume = 0.01

watch(countdown, (n) => {
  if ([3, 2, 1].includes(n)) {
    beep.play()
  } else if (n === 0) {
    finalBeep.play()

    showCountdown.value = false
    if (countdownInterval.value) clearInterval(countdownInterval.value)
    startTimer()
  }
})

function start() {
  if (props.hasCountdown) {
    showCountdown.value = true
    countdownInterval.value = setInterval(() => countdown.value--, 1000)
  } else {
    startTimer()
  }
}

function formatTimeLeft(time: number) {
  const minutes = Math.floor(time / 60)
  const seconds = time % 60

  let secondsString = seconds.toString()
  if (seconds < 10) {
    secondsString = '0' + seconds
  }
  return minutes + ':' + secondsString
}

const timerInterval = ref<ReturnType<typeof setInterval> | null>(null)

watch(timeLeft, (n) => {
  if ([10, 5, 4, 3, 2, 1].includes(n)) {
    beep.play()
  } else if (n <= 0) {
    finalBeep.play()

    if (timerInterval.value) clearInterval(timerInterval.value)
    setTimeout(() => {
      isFinished.value = true
      emit('finished')
    }, 1000)
  }
})

const isStarted = ref(false)
const isPaused = ref(false)
const isFinished = ref(false)

function startTimer() {
  if (isPaused.value) return

  isStarted.value = true

  const start = new Date()
  if (timePassed.value) {
    start.setTime(start.getTime() - timePassed.value * 1000)
  }
  timerInterval.value = setInterval(() => {
    timePassed.value = Math.floor(
      (new Date().getTime() - start.getTime()) / 1000,
    )
  }, 1000)
}

function togglePause() {
  isPaused.value = !isPaused.value
  if (!isPaused.value) {
    if (timeLeft.value > 0) {
      startTimer()
    }
  } else if (timerInterval.value) {
    clearInterval(timerInterval.value)
  }
}

const handleAnyModalOpen = () => {
  if (isStarted.value && !isFinished.value && !isPaused.value) {
    togglePause()
  }
}

const handleAnyModalClose = () => {
  if (isStarted.value && !isFinished.value && isPaused.value) {
    togglePause()
  }
}

const bindJqueryModalEvents = () => {
  const $jqueryModals = $('.modal')
  $jqueryModals.on('shown.bs.modal', handleAnyModalOpen)
  $jqueryModals.on('hidden.bs.modal', handleAnyModalClose)
}

const unbindJqueryModalEvents = () => {
  const $jqueryModals = $('.modal')
  $jqueryModals.off('shown.bs.modal', handleAnyModalOpen)
  $jqueryModals.off('hidden.bs.modal', handleAnyModalClose)
}

onMounted(() => {
  if (props.startInstantly) start()
  if (!props.isPause) bindJqueryModalEvents()
})

onBeforeUnmount(() => {
  if (countdownInterval.value) clearInterval(countdownInterval.value)
  if (timerInterval.value) clearInterval(timerInterval.value)
  if (!props.isPause) unbindJqueryModalEvents()
})

watch(
  () => props.canBeStarted,
  (n) => {
    if (n) {
      /** this is a workaround to be able to start the timer from outside of vue app context.
       * remove when everything that uses `window.startClientTimer` has been moved to vue. */
      // @ts-ignore
      window.startClientTimer = function () {
        countdown.value = props.startInstantlyCountdownSeconds
        start()
      }
    }
  },
  { immediate: true },
)

defineExpose({ start, isFinished })
</script>

<style scoped lang="scss">
/* Sets the containers height and width */
.base-timer {
  position: relative;
  width: calc(35% + 64px);
  margin: auto;
}

/* Removes SVG styling that would hide the time label */
.base-timer__circle {
  fill: none;
  stroke: none;
}

/* The SVG path that displays the timer's progress */
.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: var(--lightest-green);
}

.base-timer__label {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  letter-spacing: 2px;
}

.base-timer__label--inactive {
  opacity: 0.2;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1.2s linear all;
  stroke: var(--dark-blue);
}

.base-timer__svg {
  transform: scaleX(-1);
}

.countdown-label {
  position: absolute;
  font-size: 60px;
  top: 53%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  line-height: 1 !important;
}

.timer-buttons {
  text-align: center;
  margin-top: 16px;
}
</style>
