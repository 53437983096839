import { Model } from '@common/types/OpenAI'
import axios, { AxiosError } from 'axios'
import AuditRepository from 'trainer/src/repositories/AuditRepository'

const axiosInstance = axios.create({
  headers: {
    common: {
      'OpenAI-Organization': import.meta.env.VITE_OPENAI_API_ORG,
      Authorization: `Bearer ${import.meta.env.VITE_OPENAI_API_KEY}`,
      'Content-Type': 'application/json',
    },
  },
  baseURL: 'https://api.openai.com/v1/',
})

axiosInstance.interceptors.response.use(
  async (response) => {
    if (!response.request.responseURL.includes('models')) {
      try {
        await AuditRepository.create({
          category: 'openai',
          sub_category: 'response',
          message: 'Successful response',
          data: {
            data: response.data,
            config: response.config,
            status: response.status,
          },
        })
      } catch (e) {
        console.error('Error creating audit', e)
      }
    }

    return response
  },
  async (error) => {
    try {
      await AuditRepository.create({
        category: 'openai',
        sub_category: 'error',
        message: 'Error response',
        data: error,
      })
    } catch (e) {
      console.error('Error creating audit', e)
    }
    return Promise.reject(error)
  },
)

export const getModels = async (): Promise<Model[] | null> => {
  try {
    return (await axiosInstance.get('models')).data.data
  } catch (e) {
    console.error(e)
    return null
  }
}

export const getLatestChatModel = async (type: 'gpt-4' | 'gpt-3.5-turbo') => {
  try {
    const models = await getModels()
    if (!models) return null

    return models
      .filter(
        (model: Model) =>
          model.id.includes(type) &&
          !model.id.includes('16k') &&
          !model.id.includes('32k') &&
          !model.id.includes('instruct') &&
          !model.id.includes('realtime'),
      )
      .reduce((prev, current) =>
        prev.created > current.created ? prev : current,
      )
  } catch (e) {
    console.error(e)
    return null
  }
}

export const generateCompletion = async (
  model: string,
  messages: Array<{ role: 'system' | 'user'; content: string }>,
): Promise<[string | null, AxiosError | null]> => {
  try {
    return [
      (
        await axiosInstance.post('/chat/completions', {
          model,
          prompt,
          messages,
        })
      ).data.choices[0].message.content,
      null,
    ]
  } catch (e) {
    console.error(e)
    return [null, e as AxiosError]
  }
}

export const classifyText = async (modelId: string, document: string) => {
  try {
    return (
      await axiosInstance.post('classifications', {
        model: modelId,
        document: document,
      })
    ).data
  } catch (e) {
    console.error(e)
    return null
  }
}

export const createChatMessage = async (modelId: string, messages: any[]) => {
  try {
    return (
      await axiosInstance.post('conversations', {
        model: modelId,
        messages: messages,
      })
    ).data
  } catch (e) {
    console.error(e)
    return null
  }
}

export const searchDocuments = async (modelId: string, query: string) => {
  try {
    return (
      await axiosInstance.post('searches', {
        model: modelId,
        query: query,
      })
    ).data
  } catch (e) {
    console.error(e)
    return null
  }
}

export const summarizeDocument = async (modelId: string, document: string) => {
  try {
    return (
      await axiosInstance.post('summarizations', {
        model: modelId,
        document: document,
      })
    ).data
  } catch (e) {
    console.error(e)
    return null
  }
}
